import { Controller } from 'stimulus';

export default class extends Controller {
  static get targets() {
    return ['projectFolderInput', 'projectInput'];
  }

  fillProjectFolderField() {
    fetch(`/projects/${this.projectInputTarget.value}/folders`, {
      credentials: 'same-origin',
    })
      .then((response) => response.json())
      .then((data) => {
        const selectBox = this.projectFolderInputTarget;
        selectBox.innerHTML = '';
        data.forEach((item) => {
          const opt = document.createElement('option');
          opt.value = item.id;
          opt.innerHTML = item.name;
          selectBox.appendChild(opt);
        });
      });
  }
}
