import React from "react";

interface NameFilterProps {
    name: {
        firstName: string;
        lastName: string;
    };
    onChange: any;
}

export const NameFilter: React.FC<NameFilterProps> = ({ name, onChange }) => {
    return (
        <div className="flex items-start gap-3">
            <input
                type="name"
                name="firstName"
                id="firstName"
                className="w-full rounded-md border px-3.5 py-2.5 text-gray-900 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-insetsm:text-sm sm:leading-6"
                placeholder="First Name"
                onChange={onChange}
                value={name.firstName}
            />
            <input
                type="name"
                name="lastName"
                id="lastName"
                className="w-full rounded-md border px-3.5 py-2.5 text-gray-900 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-insetsm:text-sm sm:leading-6"
                placeholder="Last Name"
                onChange={onChange}
                value={name.lastName}
            />
        </div>
    );
};
