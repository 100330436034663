import React, { Fragment } from 'react';

import keyBy from 'lodash.keyby';

import {
  Listbox,
  Transition,
} from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function SelectCalendarCategory({ value, onChange, calendarCategories }) {
  const calendarCategoriesById = keyBy(calendarCategories, 'id');

  return (
    <Listbox value={value} onChange={onChange}>
      {({ open }) => (
        <>
          <div className="relative mt-2">
            <Listbox.Button className="flex items-center justify-between relative w-full cursor-pointer rounded-md bg-white py-1.5 px-3 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 focus:ring-2 focus:ring-inset focus:ring-blue-500">
              <div className="flex items-center gap-2">
                <div
                  className="w-4 h-4 rounded-sm"
                  style={{ backgroundColor: calendarCategoriesById[value]?.color }}
                />
                <span className="block truncate">{calendarCategoriesById[value]?.name}</span>
              </div>
              <ChevronDownIcon className="w-5 h-5" />
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-sm  ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {calendarCategories.map((calendarCategory) => (
                  <Listbox.Option
                    key={calendarCategory.id}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-blue-200' : 'text-gray-700',
                        'relative cursor-pointer select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={calendarCategory.id}
                  >
                    {({ selected, active }) => (
                      <div className="flex gap-2 items-center">
                        <div
                          className="w-4 h-4 rounded-sm"
                          style={{ backgroundColor: calendarCategory.color }}
                        />
                        <span
                          className={classNames(
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate'
                          )}
                        >
                          {calendarCategory.name}
                        </span>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
