import React, { Fragment } from 'react';

import { twMerge } from 'tailwind-merge';

import {
  Menu,
  Transition,
} from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';

export enum DropdownMenuActionType {
  Normal,
  Destructive,
}

export interface DropdownMenuProps {
  label: string;
  onClick: () => void;
  actionType?: DropdownMenuActionType;
}

export default function DropdownMenu({ items }) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-offset-gray-100">
          <span className="sr-only">Open options</span>
          <div className="p-1">
            <EllipsisVerticalIcon height={20} width={20} />
          </div>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {items.map((item, index) => (
              <Menu.Item key={index}>
                {({ active }) => (
                  <div
                    onClick={item.onClick}
                    className={twMerge(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm'
                    )}
                  >
                    <div
                      className={
                        item.actionType == DropdownMenuActionType.Destructive
                          ? 'text-red-500'
                          : 'text-gray-700'
                      }
                    >
                      <span className="text-sm leading-5 font-medium">{item.label}</span>
                    </div>
                  </div>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
