import React from 'react';

import { CalendarCategory } from '../../calendar/Calendar';
import { groupByCategoryGroup } from '../calendarCategoryUtils';
import CalendarCategoryItem from './CalendarCategoryItem';

interface CalendarCategoryListProps {
  selectedCalendarCategories: number[];
  setSelectedCalendarCategories: React.Dispatch<React.SetStateAction<number[]>>;
  calendarCategories: CalendarCategory[];
  className?: string;
}

function CalendarCategoryList({
  selectedCalendarCategories,
  setSelectedCalendarCategories,
  calendarCategories,
  className,
}: CalendarCategoryListProps) {
  const calendarGroups = groupByCategoryGroup(calendarCategories);
  const groupNames = Object.keys(calendarGroups);

  function onCheckChange(event, id) {
    const isChecked = event.target.checked;
    setSelectedCalendarCategories((prev) => {
      if (isChecked) {
        return [...prev, id];
      } else {
        return prev.filter((prevId) => prevId !== id);
      }
    });
  }

  return (
    <div className={className}>
      {groupNames.map((groupName) => {
        const calendarCategories = calendarGroups[groupName];
        return (
          <div key={groupName} className="mb-4 select-none">
            <div className="mb-2">
              <label className="text-sm font-medium leading-5 text-gray-700 whitespace-nowrap">{groupName}</label>
            </div>
            <div className="grid grid-cols-1 gap-2">
              {calendarCategories.map((calendarCategory) => (
                <CalendarCategoryItem
                  id={calendarCategory.id}
                  key={calendarCategory.id}
                  name={calendarCategory.name}
                  onCheckChange={onCheckChange}
                  checked={selectedCalendarCategories.some((id) => id === calendarCategory.id)}
                  color={calendarCategory.color}
                />
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default CalendarCategoryList;
