// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from 'stimulus';
import Flatpickr from 'stimulus-flatpickr';
import { Modal, Tabs } from 'tailwindcss-stimulus-components';
import { Autocomplete } from 'stimulus-autocomplete';
import { definitionsFromContext } from 'stimulus/webpack-helpers';
import StimulusSlimSelect from 'stimulus-slimselect';
import TabsController from './tabs_controller';

const application = Application.start();
const context = require.context('controllers', true, /_controller\.js$/);
application.load(definitionsFromContext(context));

export default class CustomModal extends Modal {
  connect() {
    super.connect();
    const hasCrop = document.querySelector('[data-controller="crop-image"]');
    if (!hasCrop && document.querySelector('.hidden-after-load')) {
      document.querySelector('.hidden-after-load').classList.add('hidden');
      document.querySelector('.hidden-after-load').classList.remove('opacity-0');
    }
  }
  cancel(event) {
    event.preventDefault();
    const hiddenInputs = this.containerTarget.querySelectorAll('[data-original-value]');
    hiddenInputs.forEach((a) => (a.value = a.dataset.originalValue));
    this.close();
  }

  close(e) {
    super.close(e);
  }

  closeWithKeyboard(e) {
    super.closeWithKeyboard(e);
  }
}

// flatpickr config
require('flatpickr/dist/flatpickr.css');
application.register('flatpickr', Flatpickr);
application.register('modal', CustomModal);
application.register('autocomplete', Autocomplete);
application.register('slimselect', StimulusSlimSelect);
application.register('tabs', TabsController);
