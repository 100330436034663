import { Controller } from "stimulus"
import VMasker from 'vanilla-masker';

export default class extends Controller {
  static get values() { return { type: String } }

  connect() {
    VMasker(this.element).maskPattern("99/99/9999");
  }
}
