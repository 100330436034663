import React, { useState, useEffect, useCallback } from 'react';

import api from '../../lib/api';
import useSWR from 'swr';
import MagicStick from '../../assets/icons/MagicStick';
import NewFeatureAlert from './NewFeatureAlert';
import ReviewFeatureCard from './ReviewFeatureCard';
import SceneCardsAIInfoCard from './SceneCardsAIInfoCard';
import SegmentedControl from './SegmentedControl';

async function fetcher(url: string) {
  const response = await api.get(url);
  return response.data;
}

export default function NeedsReview({ projectId, projectFolderId }) {
  const [reviewSceneCards, setReviewSceneCards] = useState([]);
  const [isProcessing, setIsProcessing] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState('Action Scenes');
  const [filteredSceneCards, setFilteredSceneCards] = useState([]);

  const { data, error } = useSWR(
    `/projects/${projectId}/project_folders/${projectFolderId}/processing_status`,
    fetcher,
    {
      refreshInterval: 1000,
    }
  );

  useEffect(() => {
    const processingStatus = data?.processing_status;
    const sceneCardsToBeReviewed = data?.review_scene_cards || [];

    setIsProcessing(processingStatus);
    setReviewSceneCards(sceneCardsToBeReviewed);
    setFilteredSceneCards(sceneCardsToBeReviewed);
  }, [data, error]);

  const handleOnAcceptOrArchive = (uuid) => {
    setReviewSceneCards((prevCards) => {
      const updatedCards = prevCards.filter((card) => card.uuid !== uuid);

      if (updatedCards.length === 0) {
        navigateToSceneCards();
      }

      return updatedCards;
    });
  };

  const handleSelectedFilter = (selectedControl) => {
    setSelectedFilter(selectedControl);

    if (selectedControl === 'Action Scenes') {
      setFilteredSceneCards(reviewSceneCards.filter((card) => card.scene_card_actions.length > 0));
    } else {
      setFilteredSceneCards(reviewSceneCards);
    }
  };

  const navigateToSceneCards = useCallback(async () => {
    const { Turbo } = await import('@hotwired/turbo-rails');
    Turbo.visit(`/projects/${projectId}/project_folders/${projectFolderId}`);
  }, [projectId, projectFolderId]);

  return (
    <div className="flex flex-col gap-8">
      {reviewSceneCards.length > 0 && (
        <NewFeatureAlert icon={<MagicStick />} title="Use AI to generate scene cards">
          Our AI has generated the following scene cards. Please review these to either{' '}
          <span className="font-bold">accept</span> to add them to the active list or{' '}
          <span className="font-bold">archive</span> them. Accepted scene cards will be editable.
        </NewFeatureAlert>
      )}

      <SegmentedControl
        buttons={[{ name: 'Action Scenes' }, { name: 'All Scenes' }]}
        selectedControl={selectedFilter}
        onButtonClick={handleSelectedFilter}
        className="max-w-3xl mx-auto flex-nowrap"
      />

      {isProcessing ? (
        <SceneCardsAIInfoCard
          title="AI is generating scene cards"
          description="This should take a few minutes. Come back in a little bit to view the results."
        />
      ) : (
        filteredSceneCards.length === 0 && (
          <SceneCardsAIInfoCard
            title="No scene cards to review"
            description="Currently, there are no scene cards ready for review. Once generated, they'll be displayed here for your assessment."
          />
        )
      )}

      {data?.review_scene_cards &&
        filteredSceneCards
          .sort((a, b) => a.scene - b.scene)
          .map((sceneCard) => (
            <ReviewFeatureCard
              projectId={projectId}
              projectFolderId={projectFolderId}
              sceneCard={sceneCard}
              key={sceneCard.uuid}
              onAccept={() => handleOnAcceptOrArchive(sceneCard.uuid)}
              onArchive={() => handleOnAcceptOrArchive(sceneCard.uuid)}
            />
          ))}
    </div>
  );
}
