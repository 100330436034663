import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';

import debounce from 'lodash.debounce';
import keyBy from 'lodash.keyby';
import { twMerge } from 'tailwind-merge';

import { Combobox } from '@headlessui/react';
import {
  FilmIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';

import { SceneCard } from '../calendar/Calendar';
import { IconButton } from './IconButton';

interface SelectSceneCardComboboxProps {
  handleAttachSceneCard: (sceneCard: SceneCard) => void;
  removeSceneCard: () => void;
  projectSceneCards: SceneCard[];
  currentSceneCardId: string;
}

export default function SelectSceneCardCombobox({
  currentSceneCardId,
  handleAttachSceneCard,
  projectSceneCards,
  removeSceneCard,
}: SelectSceneCardComboboxProps) {
  const [searchResults, setSearchResults] = useState<SceneCard[]>([]);

  const projectSceneCardsById = useMemo(() => keyBy(projectSceneCards, 'uuid'), [projectSceneCards]);

  const handleSearchSceneCards = useCallback(
    debounce((searchTerm) => {
      const filteredSceneCards = projectSceneCards.filter((sceneCard) => {
        const nameMatch = sceneCard.scene_name?.toLowerCase().includes(searchTerm.toLowerCase());
        const sceneNumberMatch = sceneCard.scene?.toString().includes(searchTerm.toLowerCase());
        return nameMatch || sceneNumberMatch;
      });
      setSearchResults(filteredSceneCards);
    }, 200),
    [projectSceneCards]
  );

  return (
    <>
      <Combobox as="div">
        <div className="relative mt-2">
          <Combobox.Button className="absolute inset-y-0 left-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <FilmIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </Combobox.Button>
          <Combobox.Input
            className={twMerge(
              'outline-none block w-full rounded-md border-0 pr-3 pl-8 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 focus:ring-2 focus:ring-inset focus:ring-blue-500'
            )}
            onChange={(event) => {
              handleSearchSceneCards(event.target.value);
            }}
            displayValue={(sceneCard: SceneCard) => sceneCard?.scene_name}
            placeholder="Search for a scene card"
          />
          <Combobox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {searchResults.length > 0 ? (
              searchResults.map((sceneCard) => (
                <Combobox.Option
                  key={sceneCard.uuid}
                  value={sceneCard.uuid}
                  className={({ active }) =>
                    twMerge(
                      'relative cursor-default select-none py-2 pl-3 pr-9',
                      active ? 'bg-blue-200' : 'text-gray-700'
                    )
                  }
                  onClick={() => {
                    handleAttachSceneCard(sceneCard);
                  }}
                >
                  <div className="flex gap-1">
                    <span className="font-bold">{sceneCard.scene}</span>
                    <span>{sceneCard.scene_name}</span>
                  </div>
                </Combobox.Option>
              ))
            ) : (
              <span className="relative cursor-default select-none py-4 pl-5 pr-9 flex items-center text-gray-700">
                No results found
              </span>
            )}
          </Combobox.Options>
        </div>
      </Combobox>
      {!!currentSceneCardId && (
        <div className="border py-1.5 rounded-md px-3 text-sm mt-2">
          <div className="flex justify-between items-center">
            <div className="flex gap-1">
              <span className="font-bold">{projectSceneCardsById[currentSceneCardId]?.scene}</span>
              <span>{projectSceneCardsById[currentSceneCardId]?.scene_name}</span>
            </div>
            <IconButton onClick={removeSceneCard}>
              <XMarkIcon className=" text-gray-500 w-4 h-4" />
            </IconButton>
          </div>
        </div>
      )}
    </>
  );
}
