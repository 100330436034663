import React from 'react';
import Button from '../shared/Button';
import ArchiveIcon from '../../assets/icons/ArchiveIcon';
import CircledCheckmarkIcon from '../../assets/icons/CircledCheckmarkIcon';

interface ReviewFeatureCardHeaderProps {
  sceneNumber?: string;
  sceneTitle?: string;
  onAccept: () => void;
  onArchive: () => void;
}

export default function ReviewFeatureCardHeader({
  sceneNumber = '',
  sceneTitle = '',
  onAccept,
  onArchive,
}: ReviewFeatureCardHeaderProps) {
  const handleAcceptClick = (event) => {
    event.stopPropagation();
    onAccept();
  };

  const handleArchiveClick = (event) => {
    event.stopPropagation();
    onArchive();
  };

  return (
    <div className="flex justify-between items-center">
      <div className="flex gap-2 items-center">
        <span className="text-lg text-gray-700 leading-6 font-medium">{sceneNumber}</span>
        <span className="text-lg text-gray-500 leading-7 font-normal">{sceneTitle}</span>
      </div>
      <div className="flex gap-3">
        <Button
          className="text-sm text-gray-700 border border-gray-300 bg-transparent hover:bg-transparentrounded-md rounded-md hover:shadow-md"
          onClick={handleArchiveClick}
        >
          <div className="flex items-center">
            <ArchiveIcon className="md:mr-1" />
            <span className="hidden sm:block">Archive</span>
          </div>
        </Button>
        <Button className="text-sm text-white hover:shadow-md" onClick={handleAcceptClick}>
          <div className="flex items-center">
            <CircledCheckmarkIcon className="md:mr-1" />
            <span className="hidden sm:block">Accept</span>
          </div>
        </Button>
      </div>
    </div>
  );
}
