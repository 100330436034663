import React from 'react';

import OpenBetaTag from './OpenBetaTag';

export default function NewFeatureAlert(props) {
  return (
    <div className="flex flex-col bg-blue-50 rounded-lg p-4 gap-2">
      <div className="flex">
        <div className="flex gap-3">
          {props.icon}
          <span className="text-blue-800 text-sm font-medium">{props.title}</span>
          <OpenBetaTag />
        </div>
      </div>
      <span className="text-sm leading-5 font-normal text-blue-800">{props.children}</span>
    </div>
  );
}
