import React from 'react';

export default function UserHandsIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99876 1.04199C7.81263 1.04199 6.04042 2.8142 6.04042 5.00033C6.04042 7.18645 7.81263 8.95866 9.99876 8.95866C12.1849 8.95866 13.9571 7.18645 13.9571 5.00033C13.9571 2.8142 12.1849 1.04199 9.99876 1.04199ZM7.29042 5.00033C7.29042 3.50455 8.50299 2.29199 9.99876 2.29199C11.4945 2.29199 12.7071 3.50455 12.7071 5.00033C12.7071 6.4961 11.4945 7.70866 9.99876 7.70866C8.50299 7.70866 7.29042 6.4961 7.29042 5.00033Z"
        fill="#A1A1AA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66542 10.2087C4.92289 10.2087 3.4562 11.5129 3.2526 13.2435L2.71137 17.844C2.67104 18.1868 2.91625 18.4974 3.25907 18.5377C3.60188 18.578 3.91248 18.3328 3.95281 17.99L4.49404 13.3896C4.59827 12.5036 5.22281 11.7932 6.04042 11.5495L6.04042 15.0437C6.0404 15.7924 6.04038 16.4167 6.10702 16.9124C6.17735 17.4356 6.33208 17.9079 6.71164 18.2874C7.0912 18.667 7.56353 18.8217 8.08669 18.8921C8.58234 18.9587 9.20668 18.9587 9.9554 18.9587H10.0421C10.7908 18.9587 11.4152 18.9587 11.9108 18.8921C12.434 18.8217 12.9063 18.667 13.2859 18.2874C13.6654 17.9079 13.8202 17.4356 13.8905 16.9124C13.9571 16.4167 13.9571 15.7924 13.9571 15.0437L13.9571 11.5495C14.7747 11.7932 15.3992 12.5036 15.5035 13.3896L16.0447 17.99C16.085 18.3328 16.3956 18.578 16.7385 18.5377C17.0813 18.4974 17.3265 18.1868 17.2861 17.844L16.7449 13.2435C16.5413 11.5129 15.0746 10.2087 13.3321 10.2087H6.66542ZM7.29042 15.0003V11.4587H12.7071V15.0003C12.7071 15.8037 12.7058 16.3433 12.6516 16.7458C12.5999 17.1304 12.5106 17.295 12.402 17.4036C12.2934 17.5122 12.1288 17.6015 11.7443 17.6532C11.3417 17.7073 10.8021 17.7087 9.99876 17.7087C9.19542 17.7087 8.65578 17.7073 8.25325 17.6532C7.8687 17.6015 7.70412 17.5122 7.59552 17.4036C7.48692 17.295 7.39757 17.1304 7.34587 16.7458C7.29175 16.3433 7.29042 15.8037 7.29042 15.0003Z"
        fill="#A1A1AA"
      />
    </svg>
  );
}
