import React, { Fragment } from 'react';

import keyBy from 'lodash.keyby';
import { twMerge } from 'tailwind-merge';

import {
  Listbox,
  Transition,
} from '@headlessui/react';
import {
  ChevronDownIcon,
  QueueListIcon,
  Squares2X2Icon,
  ViewColumnsIcon,
} from '@heroicons/react/24/outline';

const options = {
  monthly: {
    label: 'Month',
    icon: <Squares2X2Icon className="text-gray-500 w-4 h-4" />,
    value: 'dayGridMonth',
  },
  weekly: {
    label: 'Week',
    icon: <ViewColumnsIcon className="text-gray-500 w-4 h-4" />,
    value: 'timeGridWeek',
  },
  daily: {
    label: 'Day',
    icon: <QueueListIcon className="text-gray-500 w-4 h-4" />,
    value: 'timeGridDay',
  },
};

interface SelectViewProps {
  value: string;
  onChange: (value: string) => void;
  className?: string;
}

export function SelectCalendarView({ value, onChange, className }: SelectViewProps) {
  const optionsByValue = keyBy(options, 'value');

  return (
    <Listbox value={value} onChange={onChange}>
      {({ open }) => (
        <>
          <div className={twMerge('relative', className)}>
            <Listbox.Button className="flex items-center justify-between relative w-32 cursor-pointer rounded-md bg-white py-1.5 px-5 text-left text-gray-900 shadow-sm sm:text-sm sm:leading-6 border border-gray-300">
              <div className="flex items-center gap-2">
                <span className="block truncate">{optionsByValue[value]?.label}</span>
              </div>
              <ChevronDownIcon className="text-gray-500 w-4 h-4" />
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 mr-20 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-sm outline-none sm:text-sm border">
                {Object.keys(options).map((key) => {
                  return (
                    <Listbox.Option
                      key={key}
                      className={({ active }) =>
                        twMerge(
                          active ? 'bg-blue-200 text-white' : 'text-gray-700',
                          'text-gray-700 relative cursor-pointer select-none py-2 pl-3 pr-9'
                        )
                      }
                      value={options[key].value}
                    >
                      {({ selected, active }) => (
                        <div className="flex gap-2 items-center w-full">
                          {options[key].icon}
                          <span
                            className={twMerge(
                              selected ? 'font-semibold' : 'font-normal',
                              'text-gray-700 block truncate'
                            )}
                          >
                            {options[key].label}
                          </span>
                        </div>
                      )}
                    </Listbox.Option>
                  );
                })}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
