import React from 'react';

import { twMerge } from 'tailwind-merge';

import {
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';

import SidebarCollapseIcon from '../shared/SidebarCollapseIcon';

import Button from '../shared/Button';
import { SelectCalendarView } from './SelectCalendarView';

interface CalendarToolbarProps {
  className?: string;
  onSettingsButtonClick: () => void;
  goToPrevious: () => void;
  goToNext: () => void;
  goToToday: () => void;
  getTitle: () => string;
  changeCalendarView: (view: string) => void;
  currentView: string;
}

export function CalendarToolbar({
  className,
  onSettingsButtonClick,
  goToPrevious,
  goToNext,
  goToToday,
  getTitle,
  changeCalendarView,
  currentView,
}: CalendarToolbarProps) {
  const handleChangeView = (value) => {
    if (!value) return;
    changeCalendarView(value);
  };

  return (
    <div className={twMerge('flex items-center gap-2 py-2', className)}>
      <button
        className="bg-blue-100 p-2 rounded-md hover:bg-blue-200"
        onClick={onSettingsButtonClick}
      >
        <SidebarCollapseIcon />
      </button>
      <div className="flex gap-1">
        <Button className="flex-grow-0" onClick={goToPrevious} variant="secondary">
          <ChevronLeftIcon className="w-5 h-5" />
        </Button>
        <Button className="flex-grow-0" onClick={goToNext} variant="secondary">
          <ChevronRightIcon className="w-5 h-5" />
        </Button>
      </div>
      <Button className="flex-grow-0" onClick={goToToday} variant="secondary">
        Today
      </Button>
      <span className="flex-grow-0 text-lg leading-8 font-medium text-gray-900">{getTitle()}</span>
      <div className="flex-grow" />

      <SelectCalendarView
        className="hidden sm:block"
        value={currentView}
        onChange={handleChangeView}
      />
    </div>
  );
}
