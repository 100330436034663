import React, { useEffect, useMemo, useRef } from 'react';

import { format, isSameDay } from 'date-fns';
import Linkify from 'linkify-react';
import { twMerge } from 'tailwind-merge';

import {
  ArrowRightIcon,
  ArrowTopRightOnSquareIcon,
  CalendarDaysIcon,
  ClockIcon,
  FilmIcon,
  MapPinIcon,
  PencilSquareIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';

import ParticipantItem from '../search/ParticipantItem';
import Button from '../shared/Button';
import { IconButton } from '../shared/IconButton';
import { CalendarCategory, CalendarEvent, ProjectMember, ProjectRole, SceneCard } from './Calendar';
import { formatDates } from './eventUtils';
import { isPromiseLike } from 'swr/_internal';

interface EventDetailsItemProps {
  className?: string;
  children: React.ReactNode;
}

interface ParticipantListItemProps {
  email: string;
  projectMembersByEmail: Record<string, ProjectMember>;
}

interface EventDetailsProps {
  selectedEvent: CalendarEvent;
  projectMembersByEmail: Record<string, ProjectMember>;
  calendarCategoriesById: {
    [key: number]: CalendarCategory;
  };
  setMode: (mode: string) => void;
  setIsEventInfoOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isProjectAdmin: boolean;
  sceneCard?: SceneCard;
  handleSceneCardRedirect: (id: string) => void;
  projectRole: ProjectRole;
}

function EventDetailsItem({ className, children }: EventDetailsItemProps) {
  return (
    <div
      className={twMerge(
        'flex items-center gap-2 text-sm leading-5 font-medium text-gray-700',
        className
      )}
    >
      {children}
    </div>
  );
}

function ParticipantListItem({ projectMembersByEmail, email }: ParticipantListItemProps) {
  const projectMember = projectMembersByEmail[email];
  if (!projectMember) {
    return (
      <div className="flex items-center gap-2">
        <span className="inline-block h-8 w-8 overflow-hidden rounded-full bg-gray-100">
          <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
          </svg>
        </span>
        <div className="text-sm leading-5 font-medium text-gray-700">{email}</div>
      </div>
    );
  }

  return (
    <div className="flex items-center gap-2">
      <ParticipantItem
        imageUrl={projectMember.imageUrl}
        name={projectMember.name}
        email={projectMember.email}
        role={projectMember.role}
      />
    </div>
  );
}

export default function EventDetails({
  selectedEvent,
  projectMembersByEmail,
  calendarCategoriesById,
  setMode,
  setIsEventInfoOpen,
  isProjectAdmin,
  sceneCard,
  handleSceneCardRedirect,
  projectRole,
}: EventDetailsProps) {
  const sceneCardIdRef = useRef('');
  if (!selectedEvent) return null;
  const { allDay, title, calendarCategoryId, start, end, location, description, participants } =
    selectedEvent;

  const { parsedStart, parsedEnd } = formatDates({ start, end, allDay });
  const sameDay = parsedEnd ? isSameDay(parsedStart, parsedEnd) : false;

  const canUpdateEvent = useMemo(() => {
    const allowedRoles: ProjectRole[] = [
      'production_manager',
      'coordinator',
      'stunt_office_coordinator',
      'production_manager',
    ];
    if (isProjectAdmin || allowedRoles.includes(projectRole)) return true;
    if (projectRole === 'hair_and_makeup' || projectRole === 'wardrobe') {
      const id = selectedEvent.calendarCategoryId;
      const category = calendarCategoriesById[id];
      return category.name === 'Hair & Makeup Fittings' || category.name === 'Costume Fittings';
    }
    return false;
  }, [isProjectAdmin, projectRole, selectedEvent, calendarCategoriesById]);

  useEffect(() => {
    sceneCardIdRef.current = sceneCard?.uuid;
  }, [sceneCard]);

  return (
    <div className="flex flex-col gap-2 relative h-full w-full lg:w-72">
      <div className="border-b p-4 flex items-center justify-between fixed top-18 w-full lg:static lg:w-72 bg-white shadow-sm">
        <div className="text-lg leading-6 font-medium text-gray-700">{title}</div>
        <button onClick={() => setIsEventInfoOpen(false)}>
          <XMarkIcon className="w-6 h-6 text-gray-500" />
        </button>
      </div>
      <div className="overflow-y-auto h-full pb-24">
        <div className="flex flex-col gap-4 p-4 mt-20 lg:mt-0">
          <div className="flex items-center gap-2">
            <div
              className="h-4 w-4 rounded-md"
              style={{ backgroundColor: calendarCategoriesById[calendarCategoryId].color }}
            />
            <EventDetailsItem>{calendarCategoriesById[calendarCategoryId].name}</EventDetailsItem>
          </div>
        </div>
        {sceneCard && (
          <div className="border-t py-4 px-2">
            <div className="border bg-gray-50 rounded flex items-center justify-between p-2">
              <div className="flex items-center gap-2">
                <FilmIcon className="text-gray-500 h-5 w-5" />
                <div className="flex items-center gap-1">
                  <span className="text-sm leading-5 font-medium text-gray-700">
                    {sceneCard.scene}
                  </span>
                  <span className="text-xs leading-4 font-medium text-gray-500">
                    {sceneCard.scene_name}
                  </span>
                </div>
              </div>
              <IconButton onClick={() => handleSceneCardRedirect(sceneCardIdRef.current)}>
                <ArrowTopRightOnSquareIcon className="w-5 h-5 text-blue-500" />
              </IconButton>
            </div>
          </div>
        )}
        <div className="border-t p-4 flex flex-col gap-2">
          <EventDetailsItem>
            <CalendarDaysIcon className="text-gray-500 h-5 w-5 flex-shrink-0" />
            <span>{format(parsedStart, 'PP')}</span>
            {parsedEnd && !sameDay && <ArrowRightIcon className="w-4 h-4 text-gray-500" />}
            {parsedEnd && !sameDay && <span>{format(parsedEnd, 'PP')}</span>}
          </EventDetailsItem>
          {!selectedEvent.allDay && (
            <EventDetailsItem>
              <ClockIcon className="text-gray-500 h-4 w-4" />
              <span>{format(parsedStart, 'hh:mm aaa')}</span>
              {parsedEnd && <ArrowRightIcon className="w-4 h-4 text-gray-500" />}
              {parsedEnd && <span>{format(parsedEnd, 'hh:mm aaa')}</span>}
            </EventDetailsItem>
          )}
        </div>
        {location && (
          <div className="border-t p-4">
            <EventDetailsItem>
              <MapPinIcon className="text-gray-500 h-5 w-5 flex-shrink-0" />
              {location}
            </EventDetailsItem>
          </div>
        )}
        <div className="border-t p-4 flex flex-col gap-2">
          {participants.map((participant) => {
            return (
              <ParticipantListItem
                projectMembersByEmail={projectMembersByEmail}
                email={participant}
                key={participant}
              />
            );
          })}
        </div>
        {description && (
          <div className="border-t p-4">
            <div className="text-sm leading-5 font-medium">Notes</div>
            <EventDetailsItem className="whitespace-pre-wrap break-words font-normal">
              <div>
                <Linkify
                  options={{
                    className: 'text-blue-500 hover:text-blue-700',
                    target: '_blank',
                  }}
                >
                  {description}
                </Linkify>
              </div>
            </EventDetailsItem>
          </div>
        )}
      </div>
      {canUpdateEvent && (
        <div className="px-4 shadow-2xl border-t flex items-center bg-white h-20 w-full lg:w-72">
          <Button
            className="flex items-center gap-2 flex-grow-0"
            onClick={() => setMode('edit')}
            variant="secondary"
          >
            <PencilSquareIcon className="w-4 h-4 flex-shrink-0" />
            <span className="whitespace-nowrap">Edit event</span>
          </Button>
        </div>
      )}
    </div>
  );
}
