import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CalendarDaysIcon, ClockIcon } from "@heroicons/react/24/outline";
import { format } from 'date-fns';

import {
  FieldError,
  UseFormRegister,
  Controller,
  Control,
} from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { EventFormInput } from './EventForm';

interface EventFormFieldProps {
  placeholder: string;
  errors: FieldError;
  name: keyof EventFormInput;
  register: UseFormRegister<EventFormInput>;
  required?: boolean;
  type: 'text' | 'date' | 'time' | 'textarea';
  validations?: any;
  control?: Control<EventFormInput>;
  watch?: (name?: keyof EventFormInput) => any;
  setValue?: (name: keyof EventFormInput, value: any) => void;
}

export default function EventFormField({
  placeholder,
  errors,
  name,
  register,
  type,
  required = false,
  validations,
  control,
  watch,
  setValue,
}: EventFormFieldProps) {

  if (type === 'textarea') {
    return (
      <textarea
        name={name}
        rows={4}
        id={name}
        className="outline-none block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 focus:ring-2 focus:ring-inset focus:ring-blue-500"
        placeholder={placeholder}
        {...register(name)}
      />
    );
  }
  if (type === 'date') {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          const isAllDay = watch("allDay");
          return (
            <DatePicker
              selected={
                field.value instanceof Date
                  ? field.value
                  : (typeof field.value === "string" && field.value.length > 0) || typeof field.value === "number"
                    ? new Date(new Date(field.value).getTime() + new Date(field.value).getTimezoneOffset() * 60 * 1000)
                    : undefined
              }
              onChange={date => {
                field.onChange(date);
                if (isAllDay && field.name === 'startDate') {
                  setValue('endDate', date);
                }
              }}
              onBlur={field.onBlur}
              placeholderText={placeholder}
              wrapperClassName="react-datepicker-wrapper"
              className={twMerge(
                'outline-none block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 focus:ring-2 focus:ring-inset',
                errors
                  ? 'ring-red-600 border-red-400 text-red-500 focus:ring-red-500 focus:border-red-500'
                  : 'focus:ring-blue-500'
              )}
              showIcon
              /* @ts-ignore */
              icon={<CalendarDaysIcon className="text-gray-400" />}
            />
          )
        }}
      />)
  }
  if (type === 'time') {
    return (
      <Controller
        name={name}
        control={control}
        defaultValue={new Date().toISOString()}
        render={({ field }) => {

          let timeValue = field.value;
          let correspondingDate = field.name === 'startTime' ? watch("startDate") : watch("endDate");

          if (!field.value) {
            timeValue = format(new Date(), 'HH:mm');
          }

          if ((field.name === 'startTime' || field.name === 'endTime') && field.value instanceof Date) {
            timeValue = format(field.value, 'HH:mm');
          }

          if (!correspondingDate) {
            correspondingDate = format(new Date(), 'yyyy-MM-dd');
          }

          if (correspondingDate instanceof Date) {
            correspondingDate = format(correspondingDate, 'yyyy-MM-dd');
          }

          let combinedDateTime = new Date(`${correspondingDate} ${timeValue}`);

          return (
            <DatePicker
              selected={combinedDateTime}
              showTimeSelect
              showTimeSelectOnly
              dateFormat="h:mm aa"
              timeIntervals={15}
              onChange={date => {
                field.onChange(date);
              }}
              onBlur={field.onBlur}
              placeholderText={placeholder}
              wrapperClassName="react-datepicker-wrapper"
              className={twMerge(
                'outline-none block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 focus:ring-2 focus:ring-inset',
                errors
                  ? 'ring-red-600 border-red-400 text-red-500 focus:ring-red-500 focus:border-red-500'
                  : 'focus:ring-blue-500'
              )}
              showIcon
              /* @ts-ignore */
              icon={<ClockIcon className="text-gray-400" />}
            />
          )
        }}
      />
    );
  }
  return (
    <>
      <input
        type={type}
        name={name}
        id={name}
        placeholder={placeholder}
        className={twMerge(
          'outline-none block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 focus:ring-2 focus:ring-inset',
          errors
            ? 'ring-red-600 border-red-400 text-red-500 focus:ring-red-500 focus:border-red-500'
            : 'focus:ring-blue-500'
        )}
        {...register(name, {
          required: required ? "This field can't be blank." : false,
          ...validations,
        })}
      />
      {errors && <span className="text-xs text-red-500">{errors.message}</span>}
    </>
  );
}
