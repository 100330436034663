import React from 'react';

export default function TeamPositionTooltip() {
  return (
    <div className="w-5 h-5 group relative flex flex-col items-center float-right">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
      <div className="pointer-events-none absolute bottom-0 right-0 inline-flex flex-col items-end opacity-0 mb-6 group-hover:opacity-100 group-hover:h-auto">
        <span className="w-80 relative z-10 p-2 text-xs text-white whitespace-no-wrap bg-gray-700 shadow-sm ">
          <strong>Coordinator</strong> - can invite other team members to the project and manage
          most of folders and files.
          <br />
          <strong>Performer</strong> - can see the team member's contact info and view memos.
          <br />
          <strong>Hair and Make-up / Wardrobe</strong> - can only access HMU & Wardobe Kits of team
          members and view memos in which they were tagged.
          <br />
          <strong>Production</strong> - (These can be your AD's, Directors, Dept Heads, etc.) will
          only have access to contact info, messaging, HMU and Wardrobe Kits. <br />
          <strong>Production Manager</strong> - Only production manager will have access to Work
          Kit. Can create events.
          <br />
          <strong>Stunt Office Coordinator</strong> - Same permissions as coordinator but doesn't
          appear in the members directory.
          <br />
          <strong>Coordinator Admin</strong> - Full access to the project. Can invite others to the
          team.
          <br />
          <strong>Assistant Director (AD)</strong> - Same permissions as Production but doesn't
          appear in the members directory.
        </span>
        <div className="w-5 overflow-hidden inline-block">
          <div className="h-3 w-3 ml-px bg-gray-700 -rotate-45 transform origin-top-left"></div>
        </div>
      </div>
    </div>
  );
}
