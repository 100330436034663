import React, { useState, useEffect, Fragment } from 'react';
import { User } from './Directory'
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon, XMarkIcon, AdjustmentsHorizontalIcon } from '@heroicons/react/20/solid'
import { RangeSlider } from './RangeSlider';
import { NameFilter } from './NameFilter';
import { displayImperialHeight, convertCmToInches, kgToPounds, poundsToKg, saveFiltersToLocalStorage, fetchFiltersFromLocalStorage, clearFiltersFromLocalStorage } from './directoryUtils';
import { genders, countries, ethnicities } from './directoryData'
import { CheckboxFilter } from './CheckboxFilter';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

interface CurrentUser {
  measure_system: 'imperial' | 'metric';
}

interface UserFilterProps {
  users: User[];
  currentUser: CurrentUser;
  measurementRanges: {
    height: {
      min: number;
      max: number;
    };
    weight: {
      min: number;
      max: number;
    };
  }
  onFilter: (filtered: User[]) => void;
}

const UserFilter: React.FC<UserFilterProps> = ({ users, currentUser, onFilter, measurementRanges }) => {
  const isImperial = currentUser.measure_system === 'imperial';

  const heightBounds: [number, number] = isImperial ? [convertCmToInches(measurementRanges.height.min), convertCmToInches(measurementRanges.height.max)]
    : [measurementRanges.height.min, measurementRanges.height.max];
  const weightBounds: [number, number] = isImperial ? [kgToPounds(measurementRanges.weight.min), kgToPounds(measurementRanges.weight.max)]
    : [measurementRanges.weight.min, measurementRanges.weight.max];

  const [name, setName] = useState<{ firstName: string; lastName: string }>({ firstName: '', lastName: '' });
  const [selectedCountries, setSelectedCountries] = useState<string[]>([]);
  const [selectedEthnicities, setSelectedEthnicities] = useState<string[]>([]);
  const [heightRange, setHeightRange] = useState<[number, number]>(heightBounds);
  const [weightRange, setWeightRange] = useState<[number, number]>(weightBounds);
  const [selectedGenders, setSelectedGenders] = useState<string[]>([]);
  const [open, setOpen] = useState(false)
  const [isFiltered, setIsFiltered] = useState(false);


  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName({ ...name, [e.target.name]: e.target.value });
    saveFiltersToLocalStorage('name', { ...name, [e.target.name]: e.target.value });
  }

  const handleCountryChange = (country: string) => {
    setSelectedCountries((prev) => {
      const updatedCountries = prev.includes(country) ? prev.filter(c => c !== country) : [...prev, country];
      saveFiltersToLocalStorage('selectedCountries', updatedCountries);
      return updatedCountries;
    }
    );
  };

  const handleCountryClear = () => {
    setSelectedCountries([]);
    clearFiltersFromLocalStorage('selectedCountries');
  }

  const handleEthnicityChange = (ethnicity: string) => {
    setSelectedEthnicities((prev) => {
      const updatedEthnicities = prev.includes(ethnicity) ? prev.filter(c => c !== ethnicity) : [...prev, ethnicity]
      saveFiltersToLocalStorage('selectedEthnicities', updatedEthnicities);
      return updatedEthnicities;
    }
    );
  };

  const handleEthnicityClear = () => {
    setSelectedEthnicities([]);
    clearFiltersFromLocalStorage('selectedEthnicities');
  }

  const handleGenderChange = (gender: string) => {
    setSelectedGenders((prev) => {
      const updatedGenders = prev.includes(gender) ? prev.filter(g => g !== gender) : [...prev, gender]
      saveFiltersToLocalStorage('selectedGenders', updatedGenders);
      return updatedGenders;
    }
    );
  };

  const handleGenderClear = () => {
    setSelectedGenders([]);
    clearFiltersFromLocalStorage('selectedGenders');
  }

  const handleHeightChange = (values: [number, number]) => {
    const [newMinHeight, newMaxHeight] = values;

    if (newMaxHeight < newMinHeight) {
      setHeightRange([newMinHeight, newMinHeight + 1]);
      saveFiltersToLocalStorage('heightRange', [newMinHeight, newMinHeight + 1]);
    } else {
      setHeightRange([newMinHeight, newMaxHeight]);
      saveFiltersToLocalStorage('heightRange', [newMinHeight, newMaxHeight]);
    }
  };

  const handleHeightClear = () => {
    setHeightRange(heightBounds);
    clearFiltersFromLocalStorage('heightRange');
  }

  const handleWeightChange = (values: [number, number]) => {
    const [newMinWeight, newMaxWeight] = values;

    // Ensure max is not less than min
    if (newMaxWeight < newMinWeight) {
      setWeightRange([newMinWeight, newMinWeight + 1]);
      saveFiltersToLocalStorage('weightRange', [newMinWeight, newMinWeight + 1]);
    } else {
      setWeightRange([newMinWeight, newMaxWeight]);
      saveFiltersToLocalStorage('weightRange', [newMinWeight, newMaxWeight]);
    }
  };

  const handleWeightClear = () => {
    setWeightRange(weightBounds);
    clearFiltersFromLocalStorage('weightRange');
  }

  const filters = [{
    id: 'gender',
    name: 'Gender',
    options: genders,
    value: selectedGenders,
    handler: handleGenderChange,
    clear: handleGenderClear,
    type: 'checkbox',
    label: null,
    dirty: selectedGenders.length > 0,
  },
  {
    id: 'ethnicity',
    name: 'Ethnicity',
    options: ethnicities,
    value: selectedEthnicities,
    handler: handleEthnicityChange,
    clear: handleEthnicityClear,
    type: 'checkbox',
    label: null,
    dirty: selectedEthnicities.length > 0,
  },

  {
    id: 'weight',
    name: 'Weight',
    options: [weightBounds],
    handler: handleWeightChange,
    clear: handleWeightClear,
    value: weightRange,
    type: 'range',
    label: isImperial ? `${weightRange[0]} - ${weightRange[1]} lbs` : `${weightRange[0]} - ${weightRange[1]} kg`,
    minLabel: isImperial ? `${weightRange[0]} lbs` : `${weightRange[0]} kg`,
    maxLabel: isImperial ? `${weightRange[1]} lbs` : `${weightRange[1]} kg`,
    dirty: weightRange[0] !== weightBounds[0] || weightRange[1] !== weightBounds[1],
    step: 5,
  },
  {
    id: 'height',
    name: 'Height',
    options: [heightBounds],
    handler: handleHeightChange,
    clear: handleHeightClear,
    value: heightRange,
    type: 'range',
    label: isImperial ? `${displayImperialHeight(heightRange[0])} - ${displayImperialHeight(heightRange[1])}` : `${heightRange[0]} - ${heightRange[1]} cm`,
    minLabel: isImperial ? `${displayImperialHeight(heightRange[0])}` : `${heightRange[0]} cm`,
    maxLabel: isImperial ? `${displayImperialHeight(heightRange[1])}` : `${heightRange[1]} cm`,
    dirty: heightRange[0] !== heightBounds[0] || heightRange[1] !== heightBounds[1],
    step: 1,
  }, {
    id: 'country',
    name: 'Country',
    options: countries,
    value: selectedCountries,
    handler: handleCountryChange,
    clear: handleCountryClear,
    type: 'checkbox',
    label: null,
    dirty: selectedCountries.length > 0,
  },
  ]

  useEffect(() => {
    const storedGenders = fetchFiltersFromLocalStorage('selectedGenders', []);
    const storedEthnicities = fetchFiltersFromLocalStorage('selectedEthnicities', []);
    const storedCountries = fetchFiltersFromLocalStorage('selectedCountries', []);
    const storedHeightRange = fetchFiltersFromLocalStorage('heightRange', heightBounds);
    const storedWeightRange = fetchFiltersFromLocalStorage('weightRange', weightBounds);
    const storedName = fetchFiltersFromLocalStorage('name', { firstName: '', lastName: '' });

    setSelectedGenders(storedGenders);
    setSelectedEthnicities(storedEthnicities);
    setSelectedCountries(storedCountries);
    setHeightRange(storedHeightRange);
    setWeightRange(storedWeightRange);
    setName(storedName);
  }, []);

  useEffect(() => {
    applyFilters();
  }, [selectedCountries, selectedEthnicities, heightRange, weightRange, selectedGenders, name]);

  const handleFilterChange = (filter, option) => {
    if (filter.type === 'checkbox') {
      filter.handler(option.id);
    } else if (filter.type === 'range') {
      filter.handler(option);
    }
  }

  const applyFilters = () => {
    let filtered = users;

    if (name.firstName || name.lastName) {
      filtered = filtered.filter(user => {
        if (name.firstName && name.lastName) {
          return user.firstName?.toLowerCase().includes(name.firstName?.toLowerCase()) &&
            user.lastName?.toLowerCase().includes(name.lastName?.toLowerCase());
        } else if (name.firstName) {
          return user.firstName?.toLowerCase().includes(name.firstName?.toLowerCase());
        } else if (name.lastName) {
          return user.lastName?.toLowerCase().includes(name.lastName?.toLowerCase());
        }
      });
    }

    if (selectedCountries.length) {
      filtered = filtered.filter(user => selectedCountries.includes(user.country));
    }

    if (selectedEthnicities.length) {
      filtered = filtered.filter(user =>
        user.ethnicities?.some(ethnicity => selectedEthnicities.includes(ethnicity))
      );
    }

    if (heightRange) {
      if (heightRange !== heightBounds) {
        filtered = filtered.filter(user => {
          if (isImperial) {
            const heightInInches = convertCmToInches(user.height);
            return heightInInches >= heightRange[0] && heightInInches <= heightRange[1];
          } else {
            return user.height >= heightRange[0] && user.height <= heightRange[1];
          }
        });
      }
    }

    if (weightRange) {
      if (weightBounds !== weightRange) {
        if (isImperial) {
          const minKg = poundsToKg(weightRange[0]);
          const maxKg = poundsToKg(weightRange[1]);
          filtered = filtered.filter(user => user.weight >= minKg && user.weight <= maxKg);
        } else {
          filtered = filtered.filter(user => user.weight >= weightRange[0] && user.weight <= weightRange[1]);
        }
      }
    }

    if (selectedGenders.length) {
      filtered = filtered.filter(user => selectedGenders.includes(user.gender));
    }

    setIsFiltered(filtered.length !== users.length)

    onFilter(filtered);
  };

  const clearFilters = () => {
    handleCountryClear();
    handleEthnicityClear();
    handleGenderClear();
    handleHeightClear();
    handleWeightClear();
    setName({ firstName: '', lastName: '' });
    setIsFiltered(false);
  }

  return (
    <div className="filter-root w-full">

      {/* mobile filters */}

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                <div className="flex items-center justify-between px-4">
                  <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                  <button
                    type="button"
                    className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                <div className="mt-4">
                  <Disclosure as="div" key={`filter-mobile-name`} className={classNames(name.firstName?.length > 0 || name.lastName?.length ? "bg-blue-50 text-blue-400" : "bg-white text-gray-400", "border-t border-gray-200 px-4 py-6")}>
                    {({ open }) => (
                      <>
                        <h3 className="-mx-2 -my-3 flow-root">
                          <Disclosure.Button className={classNames(name.firstName?.length > 0 || name.lastName?.length ? "bg-blue-50 text-blue-400" : "bg-white text-gray-400", "flex w-full items-center justify-between  px-2 py-3 text-sm ")}>
                            <span className="flex font-medium text-gray-900">Name
                              {name.firstName?.length > 0 || name.lastName?.length > 0 &&
                                <span className="ml-1.5 rounded bg-blue-200 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700">
                                  1
                                </span>
                              }
                            </span>

                            <span className="ml-6 flex items-center">
                              <ChevronDownIcon
                                className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-5 w-5 transform')}
                                aria-hidden="true"
                              />
                            </span>
                          </Disclosure.Button>
                        </h3>
                        <Disclosure.Panel className="pt-6">
                          <div className="space-y-6">
                            <NameFilter name={name} onChange={handleNameChange} />
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                  {filters.map((filter) => (
                    <Disclosure as="div" key={`filter-mobile-${filter.id}`} className={classNames(filter.dirty ? "bg-blue-50 text-blue-800" : "bg-white text-gray-900", "border-t border-gray-200 px-4 py-6")}>
                      {({ open }) => (
                        <>
                          <h3 className="-mx-2 -my-3 flow-root">
                            <Disclosure.Button className="flex w-full items-center justify-between px-2 py-3 text-sm">

                              <span className="flex font-medium">
                                {filter.dirty &&
                                  <span onClick={filter.clear} className="mt-0.5 mr-1.5 rounded bg-blue-200 px-0.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700">
                                    <XMarkIcon className="h-3 w-3" />
                                  </span>
                                }
                                {filter.name}
                                {filter.type === 'checkbox' && filter.value.length > 0 &&
                                  <span className="ml-1.5 rounded bg-blue-200 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700">
                                    {filter.value.length}
                                  </span>
                                }
                                {filter.type === 'range' && filter.dirty &&
                                  <span className="ml-1.5 rounded bg-blue-200 px-1 py-0.5 text-xs font-semibold tabular-nums text-gray-700">
                                    {filter.label}
                                  </span>
                                }
                              </span>
                              <span className="ml-6 flex items-center">
                                <ChevronDownIcon
                                  className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-5 w-5 transform')}
                                  aria-hidden="true"
                                />
                              </span>
                            </Disclosure.Button>
                          </h3>
                          <Disclosure.Panel className="pt-6 ">
                            <div className="space-y-6">
                              {filter.type === 'checkbox' &&
                                <CheckboxFilter filter={filter} onChange={handleFilterChange} />
                              }
                              {filter.type === 'range' &&
                                <div className="mb-3">
                                  <RangeSlider filter={filter} onChange={handleFilterChange} />
                                </div>
                              }

                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ))}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      {/* end mobile filters */}

      {/* desktop filters */}

      <section aria-labelledby="filter-heading">
        <h2 id="filter-heading" className="sr-only">
          Filters
        </h2>
        <div className="border-gray-200 pb-4">
          <div className="mx-auto flex max-w-7xl items-left justify-between">
            <button
              type="button"
              className="flex sm:hidden bg-white border rounded-md py-2.5 px-3.5 gap-3 justify-center text-sm font-medium text-gray-700 hover:text-gray-900"
              onClick={() => setOpen(true)}
            >
              <AdjustmentsHorizontalIcon className="w-6 h-6" /> <span className="pt-0.5">All Filters</span>
            </button>

            <div className="hidden sm:flex sm:flex-col gap-2">
              <NameFilter name={name} onChange={handleNameChange} />
              <div className="flow-root">
                <Popover.Group className="flex gap-3 items-center">
                  <button
                    type="button"
                    className="group bg-white border rounded-md py-2 px-3 inline-flex gap-3 justify-center text-sm font-medium text-gray-700 hover:text-gray-900"
                    onClick={() => setOpen(true)}
                  >
                    <AdjustmentsHorizontalIcon className="w-6 h-6" />
                  </button>
                  {filters.map((filter) => (
                    <Popover key={filter.id} className="relative inline-block text-left">
                      <Popover.Button className={classNames(filter.dirty ? "bg-blue-50 text-blue-800" : "text-gray-700 bg-white hover:text-gray-900", "group  border rounded-md py-2.5 px-3.5 inline-flex justify-center text-sm font-medium")}>
                        {filter.dirty &&
                          <span onClick={filter.clear} className="mt-0.5 mr-1.5 rounded bg-blue-200 px-0.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700">
                            <XMarkIcon className="h-3 w-3" />
                          </span>
                        }
                        <span>{filter.name}</span>
                        {filter.type === 'checkbox' && filter.value.length > 0 &&
                          <span className="ml-1.5 rounded bg-blue-200 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700">
                            {filter.value.length}
                          </span>
                        }
                        <ChevronDownIcon
                          className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                      </Popover.Button>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Popover.Panel className="absolute left-4 z-10 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <form className="space-y-4">
                            {filter.type === 'checkbox' &&
                              <CheckboxFilter filter={filter} onChange={handleFilterChange} />}
                            {filter.type === 'range' &&
                              <div className="p-1">
                                <RangeSlider filter={filter} onChange={handleFilterChange} />
                              </div>
                            }
                          </form>
                        </Popover.Panel>
                      </Transition>
                    </Popover>
                  ))}
                </Popover.Group>
              </div>
            </div>
          </div>
        </div>
        {isFiltered && (
          <div className="flex mb-2">
            <button onClick={clearFilters} className="flex rounded bg-blue-200 px-1.5 py-1.5 text-xs font-semibold tabular-nums text-gray-700">
              <XMarkIcon className="h-3 w-3 mt-0.5" /> Clear All Filters
            </button>
          </div>
        )}
      </section>
    </div >
  );
}

export default UserFilter;
