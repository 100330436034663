import { Controller } from 'stimulus';
import Cropper from 'cropperjs';

export default class extends Controller {
  static get targets() {
    return [
      'container',
      'finalImage',
      'image',
      'newImage',
      'cropX',
      'cropY',
      'cropWidth',
      'cropHeight',
    ];
  }

  connect() {
    if (!this.loaded) {
      if (document.querySelector('.hidden-after-load')) {
        document.querySelector('.hidden-after-load').classList.add('hidden');
        document.querySelector('.hidden-after-load').classList.remove('opacity-0');
      }
      this.loaded = true;
    }
  }

  start() {
    const _this = this;
    _this.cropXTarget.dataset.originalValue = this.cropXTarget.value;
    _this.cropYTarget.dataset.originalValue = this.cropYTarget.value;
    _this.cropWidthTarget.dataset.originalValue = this.cropWidthTarget.value;
    _this.cropHeightTarget.dataset.originalValue = this.cropHeightTarget.value;
    _this.originalImageUrl = _this.imageTarget.src;
    _this.originalData = {
      x: +_this.cropXTarget.value,
      y: +_this.cropYTarget.value,
      width: +_this.cropWidthTarget.value,
      height: +_this.cropHeightTarget.value,
    };
    _this.originalFinalImageTarget = this.finalImageTarget;

    if (!_this.cropper) {
      _this.cropper = new Cropper(_this.imageTarget, {
        aspectRatio: 1,
        viewMode: 1,
        minContainerWidth: this.containerTarget.offsetWidth,
        minContainerHeight: 400,
        autoCropArea: 1,
        dragMode: 'move',
        restore: false,
        guides: true,
        center: false,
        highlight: false,
        cropBoxMovable: true,
        cropBoxResizable: true,
        toggleDragModeOnDblclick: false,
        ready() {
          if (_this.originalData.width && _this.originalData.height) {
            _this.cropper.setData(_this.originalData);
            _this.cropXTarget.value = _this.originalData.x;
            _this.cropYTarget.value = _this.originalData.y;
            _this.cropWidthTarget.value = _this.originalData.width;
            _this.cropHeightTarget.value = _this.originalData.height;
          } else {
            _this.cropper.setCropBoxData({
              left: "50%",
              top: 0,
            });
          }
        },
        crop(event) {
          _this.cropXTarget.value = event.detail.x;
          _this.cropYTarget.value = event.detail.y;
          _this.cropWidthTarget.value = event.detail.width;
          _this.cropHeightTarget.value = event.detail.height;
        },
      });

      this.newImageTarget.onchange = (event) => {
        if (_this.newImageUrl) {
          URL.revokeObjectURL(_this.newImageUrl);
        }
        _this.newImageUrl = URL.createObjectURL(event.target.files[0]);
        _this.cropper.replace(_this.newImageUrl);
      };
    }
  }

  continue() {
    this.originalData = this.cropper.getData();
    const croppedCanvas = this.cropper.getCroppedCanvas({
      height: this.finalImageTarget.height,
      width: this.finalImageTarget.width,
    });
    croppedCanvas.dataset.cropImageTarget = 'finalImage';
    this.originalFinalImageTarget.replaceWith(croppedCanvas);
    this.originalFinalImageTarget = croppedCanvas;
  }

  cancel() {
    if (this.newImageUrl) {
      URL.revokeObjectURL(this.newImageUrl);
    }
    if (this.originalImageUrl != this.imageTarget.src) {
      this.cropper.replace(this.originalImageUrl);
      this.newImageTarget.value = null;
    }
    this.cropper.setData(this.originalData);
  }
}
