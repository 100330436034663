import ReactOnRails from 'react-on-rails';
import { Toaster } from 'react-hot-toast';
import Breadcrumb from './shared/Breadcrumb';
import Calendar from './calendar/Calendar';
import CalendarCategoryList from './calendarCategories/calendar/CalendarCategoryList';
import CalendarCategoriesSettings from './calendarCategories/settings/CalendarCategoriesSettings';
import Directory from './directory/Directory';
import GenerateAISceneDropdownItem from './documents/GenerateAISceneDropdownItem';
import Footer from './shared/Footer';
import SceneCardAIBetaAlert from './sceneCards/SceneCardAIBetaAlert';
import SceneActionsOverview from './sceneCards/SceneActionsOverview';
import SceneCardCalendarEvent from './sceneCards/SceneCardCalendarEvent';
import SceneCardEventsList from './sceneCards/SceneCardEventsList';
import SceneCardsPermissionsDialog from './shared/SceneCardsPermissionsDialog';
import LottieMagicStick from './sceneCards/LottieMagicStick';
import MagicStick from '../assets/icons/MagicStick';
import NeedsReview from './sceneCards/NeedsReview';
import TeamPositionTooltip from './team/TeamPositionTooltip';
import InactiveSubscriptionAlert from './shared/InactiveSubscriptionAlert';

ReactOnRails.setOptions({ turbo: true } as any);
ReactOnRails.register({
  Breadcrumb,
  CalendarCategoryList,
  Calendar,
  CalendarCategoriesSettings,
  Directory,
  Footer,
  GenerateAISceneDropdownItem,
  LottieMagicStick,
  MagicStick,
  NeedsReview,
  SceneCardAIBetaAlert,
  SceneActionsOverview,
  SceneCardEventsList,
  SceneCardCalendarEvent,
  SceneCardsPermissionsDialog,
  Toaster,
  TeamPositionTooltip,
  InactiveSubscriptionAlert,
});
