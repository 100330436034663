import React from 'react';

import { XMarkIcon } from '@heroicons/react/24/outline';

import ParticipantItem from './ParticipantItem';

interface ParticipantCardItemProps {
  email: string;
  name?: string;
  role?: string;
  imageUrl?: string;
  onClose: () => void;
}

const ParticipantCardItem: React.FC<ParticipantCardItemProps> = ({
  email,
  name,
  role,
  imageUrl,
  onClose,
}) => {
  return (
    <div className="flex items-center rounded-md px-3 py-2 border border-gray-300">
      <div className="flex-grow min-w-0 mr-3">
        <ParticipantItem imageUrl={imageUrl} name={name} email={email} role={role} />
      </div>
      <button
        type="button"
        className="ml-auto -mx-1.5 -my-1.5 text-gray-400 p-1.5 hover:bg-transparent inline-flex items-center justify-center h-8 w-8"
        onClick={onClose}
        aria-label="Close"
      >
        <span className="sr-only">Close</span>
        <XMarkIcon className="w-4 h-4 text-gray-400 hover:text-gray-500" />
      </button>
    </div>
  );
};

export default ParticipantCardItem;
