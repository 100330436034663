import React from 'react';

import { format } from 'date-fns';

import { EventContentArg } from '@fullcalendar/core';

interface Props {
  eventInfo: EventContentArg;
}

export default function EventContent({ eventInfo }: Props) {
  const viewType = eventInfo.view.type; // "timeGridWeek" | "dayGridMonth" | "timeGridDay"
  const isAllDay = eventInfo.event.allDay

  const monthlyViewTime = format(new Date(eventInfo.event.start), 'haaa');
  if (viewType === 'dayGridMonth') {
    return (
      <div
        className="cursor-pointer overflow-hidden w-full p-0.5"
        style={{
          borderRadius: '4px',
          backgroundColor: eventInfo.backgroundColor,
        }}
      >
        <div className="flex items-center gap-1 text-gray-700 font-medium">
          {!isAllDay && <div className="font-bold">{monthlyViewTime}</div>}
          <div className="whitespace-nowrap overflow-hidden">{eventInfo.event.title}</div>
        </div>
      </div>
    );
  }

  return (
    <div className="cursor-pointer text-gray-700">
      <div className="text-xs font-medium leading-none whitespace-nowrap	overflow-hidden">
        {eventInfo.timeText}
      </div>
      <div className="text-sm font-medium leading-4 whitespace-nowrap overflow-hidden">
        {eventInfo.event.title}
      </div>
    </div>
  );
}
