import React, { useCallback } from 'react';

interface Props {
  children: React.ReactNode;
  onClick: () => void;
  className?: string;
}

export function IconButton({ children, onClick, className }: Props) {
  const handleClick = useCallback((event) => {
    event?.preventDefault();
    onClick();
  }, []);

  return (
    <button type="button" className={className} onClick={handleClick}>
      {children}
    </button>
  );
}
