import { InformationCircleIcon } from "@heroicons/react/20/solid";
import React from "react";

interface RangeSliderProps {
    filter: any;
    onChange: any;
    step?: number;
}

export const RangeSlider: React.FC<RangeSliderProps> = ({ filter, onChange }) => {

    return (
        <>
            <div className="flex flex-col w-72">
                <div className="flex flex-col bg-blue-100 rounded-lg">
                    <input
                        type="range"
                        min={filter.options[0][0]}
                        max={filter.options[0][1]}
                        value={filter.value[0]}
                        step={filter.step}
                        onChange={e => onChange(filter, [+e.target.value, filter.value[1]])}
                        className="min-slider h-2 bg-blue-100 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                    />
                    <input
                        type="range"
                        min={filter.options[0][0]}
                        max={filter.options[0][1]}
                        value={filter.value[1]}
                        step={filter.step}
                        onChange={e => onChange(filter, [filter.value[0], +e.target.value])}
                        className="max-slider h-2 bg-blue-100 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                    />
                </div>
                <div className="flex mt-2">
                    <div><span className="text-gray-900">{filter.minLabel}</span></div>
                    <div className="flex-1"></div>
                    <div><span className="text-gray-900">{filter.maxLabel}</span></div>
                </div>
                <div className="flex text-xs mt-2">
                    <InformationCircleIcon className="text-blue-500 mr-1" width={16} height={16} /> <span>Configure the measurement system in your <a className="underline" href="/auth/users/edit">profile</a>.</span>
                </div>
            </div>
        </>
    );

};