import React, { useCallback } from 'react';

import { twMerge } from 'tailwind-merge';

import {
  Cog6ToothIcon as GearIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';

import CalendarCategoryList from '../calendarCategories/calendar/CalendarCategoryList';
import Button from '../shared/Button';
import { IconButton } from '../shared/IconButton';
import { CalendarCategory } from './Calendar';

interface SettingsSideBarProps {
  className?: string;
  calendarId: string;
  calendarCategories: CalendarCategory[];
  selectedCalendarCategories: number[];
  setSelectedCalendarCategories: React.Dispatch<React.SetStateAction<number[]>>;
  isProjectAdmin: boolean;
}

interface MobileSettingsSideBarProps extends SettingsSideBarProps {
  initialView: string;
  changeCalendarView: (view: string) => void;
  setIsSettingsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currentView: string;
}

export function SettingsSideBar({
  className,
  calendarId,
  calendarCategories,
  selectedCalendarCategories,
  setSelectedCalendarCategories,
  isProjectAdmin,
}: SettingsSideBarProps) {
  const navigateToSettings = useCallback(async () => {
    const { Turbo } = await import('@hotwired/turbo-rails');
    Turbo.visit(`/calendars/${calendarId}/settings`);
  }, [calendarId]);

  return (
    <div className={className}>
      <div className="p-4">
        <CalendarCategoryList
          selectedCalendarCategories={selectedCalendarCategories}
          setSelectedCalendarCategories={setSelectedCalendarCategories}
          calendarCategories={calendarCategories}
        />
      </div>
      <div className="flex-grow" />
      {isProjectAdmin && (
        <div className="border-t p-4">
          <Button
            className="flex-grow-0 flex items-center gap-2"
            onClick={navigateToSettings}
            variant="secondary"
          >
            <GearIcon className="w-4 h-4" />
            <span>Settings</span>
          </Button>
        </div>
      )}
    </div>
  );
}

export function MobileSettingsSideBar({
  className,
  calendarId,
  calendarCategories,
  selectedCalendarCategories,
  setSelectedCalendarCategories,
  changeCalendarView,
  setIsSettingsOpen,
  currentView,
  isProjectAdmin,
}: MobileSettingsSideBarProps) {
  const navigateToSettings = useCallback(async () => {
    const { Turbo } = await import('@hotwired/turbo-rails');
    Turbo.visit(`/calendars/${calendarId}/settings`);
  }, [calendarId]);

  const handleChangeView = (value) => {
    if (!value) return;
    changeCalendarView(value);
    setIsSettingsOpen(false);
  };

  return (
    <div className="overflow-y-auto" style={{ height: 'calc(100vh - 64px)', maxHeight: '840px' }}>
      <div className="p-4 border-b flex items-center justify-between">
        <div className="text-lg leading-6 font-medium text-gray-900">Calendar</div>
        <IconButton onClick={() => setIsSettingsOpen(false)}>
          <XMarkIcon className="w-6 h-6 text-gray-500" />
        </IconButton>
      </div>
      <div className="flex flex-col gap-4 p-4">
        <div>
          <div className="text-sm leading-5 font-medium text-gray-700 mb-2">
            Select calendar view
          </div>
          <div className="flex">
            <button
              className={twMerge(
                'text-sm leading-5 font-medium text-gray-700 flex-1 px-4 py-2 border rounded-l',
                currentView === 'dayGridMonth' ? 'bg-blue-200 text-blue-500' : ''
              )}
              onClick={() => handleChangeView('dayGridMonth')}
            >
              Month
            </button>
            <button
              className={twMerge(
                'text-sm leading-5 font-medium text-gray-700 flex-1 px-4 py-2 border-t border-b',
                currentView === 'timeGridWeek' ? 'bg-blue-200 text-blue-500' : ''
              )}
              onClick={() => handleChangeView('timeGridWeek')}
            >
              Week
            </button>
            <button
              className={twMerge(
                'text-sm leading-5 font-medium text-gray-700 flex-1 px-4 py-2 border rounded-r',
                currentView === 'timeGridDay' ? 'bg-blue-200 text-blue-500' : ''
              )}
              onClick={() => handleChangeView('timeGridDay')}
            >
              Day
            </button>
          </div>
        </div>
        <CalendarCategoryList
          selectedCalendarCategories={selectedCalendarCategories}
          setSelectedCalendarCategories={setSelectedCalendarCategories}
          calendarCategories={calendarCategories}
        />
      </div>
      <div className="flex-grow" />
      {isProjectAdmin && (
        <div className="border-t p-4">
          <Button
            className="flex-grow-0 flex items-center gap-2"
            onClick={navigateToSettings}
            variant="secondary"
          >
            <GearIcon className="w-4 h-4" />
            <span>Settings</span>
          </Button>
        </div>
      )}
    </div>
  );
}
