import { Dropdown } from 'tailwindcss-stimulus-components';

export default class DropdownPrevent extends Dropdown {
  show(event) {
    event.preventDefault();
    super.show();
  }

  toggle(event) {
    !this.openValue && event.preventDefault();
    super.toggle();
  }
}
