import React from 'react';

import toast, { ToastPosition } from 'react-hot-toast';
import { twMerge } from 'tailwind-merge';

import {
  CheckIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';

interface ToastProps {
  text: string;
  type: 'success' | 'danger' | 'warning';
  onClose: () => void;
  customColor?: string;
  customIcon?: React.ReactNode;
}

export const showToast = (
  text: string,
  type: 'success' | 'danger',
  position: ToastPosition = 'bottom-center'
) => {
  const toastId = toast.custom(
    <Toast text={text} type={type} onClose={() => toast.remove(toastId)} />,
    {
      duration: 3000,
      position,
    }
  );
};

const Toast: React.FC<ToastProps> = ({ text, type, onClose }) => {
  const variantToBgColor = {
    success: `bg-green-50`,
    danger: `bg-red-50`,
    warning: `bg-yellow-50`,
  };

  const variantToTextColor = {
    success: `text-green-800`,
    danger: `text-red-800`,
    warning: `text-yellow-800`,
  };

  const variantToIconColor = {
    success: `text-green-500`,
    danger: `text-red-500`,
    warning: `text-yellow-500`,
  };

  const variantToIcon = {
    success: <CheckIcon className="w-5 h-5" />,
    danger: <ExclamationTriangleIcon className="w-5 h-5" />,
    warning: <InformationCircleIcon className="w-5 h-5" />,
  };

  return (
    <div
      className={twMerge(
        'flex items-center justify-between w-full max-w-xs p-4 mb-4 rounded-md shadow',
        variantToBgColor[type]
      )}
      role="alert"
    >
      <div className="flex">
        <div
          className={twMerge(
            'flex items-center justify-center flex-shrink-0 h-5 w-5',
            variantToIconColor[type]
          )}
        >
          {variantToIcon[type]}
          <span className="sr-only">Icon</span>
        </div>
        <div className={twMerge('ml-3 text-sm leading-5 font-medium', variantToTextColor[type])}>
          {text}
        </div>
      </div>
      <button type="button" onClick={onClose} aria-label="Close">
        <XMarkIcon className={twMerge('h-5 w-5', variantToIconColor[type])} />
        <span className="sr-only">Close</span>
      </button>
    </div>
  );
};

export default Toast;
