import React, {
  useCallback,
  useMemo,
} from 'react';

import {
  format,
  getUnixTime,
} from 'date-fns';

import { filterEventsByCategory } from './SceneCardCalendarEvent';

interface SceneCardEventsListProps {
  calendarId: string;
  events: any;
  calendarCategories: any;
  category: 'shootDays' | 'rehearsalDays' | 'scoutLocation';
}

export default function SceneCardEventsList({
  calendarId,
  calendarCategories,
  category,
  events,
}: SceneCardEventsListProps) {
  const eventList = useMemo(
    () =>
      filterEventsByCategory({
        calendarCategories,
        events,
        category,
      }).filter((event) => event.allDay),
    [calendarCategories, events, category]
  );

  const navigateToCalendar = useCallback(
    async ({ eventId, eventDate }) => {
      const { Turbo } = await import('@hotwired/turbo-rails');
      Turbo.visit(
        `/calendars/${calendarId}?selected_event_id=${eventId}&selected_event_date=${getUnixTime(
          new Date(eventDate)
        )}`
      );
    },
    [calendarId]
  );

  if (!eventList.length) {
    return (
      <div className="flex items-center gap-2 flex-wrap">
        <span className="text-xs leading-5 font-medium text-gray-500">No selected dates yet</span>
      </div>
    );
  }

  return (
    <div className="flex items-center gap-2 flex-wrap">
      {eventList.map((event) => {
        return (
          <span
            onClick={() =>
              navigateToCalendar({ eventId: event.id, eventDate: event.start || event.start_date })
            }
            className="hover:text-gray-700 cursor-pointer text-sm leading-5 font-medium text-gray-500"
            key={event.id}
          >
            {format(new Date((event.start || event.start_date) + 'T00:00:00'), 'MMM dd')}
          </span>
        );
      })}
    </div>
  );
}
