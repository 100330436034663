import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['genderInput', 'genderButton', 'dropdown', 'genderName'];
  static values = { dropdownOpen: Boolean };

  connect() {
    this.dropdownOpenValue = false;
  }

  toggleDropdown(event) {
    event.stopPropagation();
    this.dropdownOpenValue = !this.dropdownOpenValue;
    this.dropdownTarget.classList.toggle('hidden', !this.dropdownOpenValue);
  }

  setGender(event) {
    event.preventDefault();
    const gender = event.target.dataset.gender;
    this.genderInputTarget.value = gender;
    this.genderNameTarget.textContent = event.target.textContent;
    this.dropdownOpenValue = false;
    this.dropdownTarget.classList.add('hidden');
  }

  closeDropdown() {
    if (this.dropdownOpenValue) {
      this.dropdownOpenValue = false;
      this.dropdownTarget.classList.add('hidden');
    }
  }

  stopPropagation(event) {
    event.stopPropagation();
  }
}
