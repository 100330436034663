import React, {
  useEffect,
  useState,
} from 'react';

import { twMerge } from 'tailwind-merge';

import { Combobox } from '@headlessui/react';
import {
  ChevronUpDownIcon,
  PlusIcon,
} from '@heroicons/react/20/solid';

import ParticipantItem from './ParticipantItem';

interface ParticipantsComboboxProps {
  participants: Participant[];
  handleNewParticipant: (participant: Participant) => void;
  onSearch: (query: string) => void;
  disableAddNonParticipants?: boolean;
}

export interface Participant {
  id: number;
  name?: string;
  email: string;
  role?: string;
  imageUrl?: string;
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function isValidEmail(email) {
  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return emailRegex.test(email);
}

function getOptionValue({
  participants,
  handleNewParticipant,
  disableAddNonParticipants,
  query,
  setError,
}) {
  if (participants.length > 0) {
    return participants.map((participant) => (
      <Combobox.Option
        key={participant.id}
        value={participant.name}
        className={({ active }) =>
          classNames(
            'relative cursor-default select-none py-2 pl-3 pr-9',
            active ? 'bg-blue-200 text-white' : 'text-gray-700'
          )
        }
        onClick={() => {
          handleNewParticipant(participant);
        }}
      >
        <ParticipantItem
          imageUrl={participant.imageUrl}
          name={participant.name}
          email={''}
          role={participant.role}
        />
      </Combobox.Option>
    ));
  }

  if (disableAddNonParticipants) {
    return (
      <span
        className={classNames(
          'relative cursor-default select-none py-4 pl-5 pr-9 flex items-center text-gray-700'
        )}
      >
        No results were found
      </span>
    );
  }

  return (
    <Combobox.Option
      value="Add New Participant"
      onClick={() => {
        if (!isValidEmail(query)) {
          return setError(true);
        }
        const newParticipant = { id: Date.now(), email: query };
        handleNewParticipant(newParticipant);
      }}
    >
      {({ active }) => (
        <span
          className={classNames(
            'relative cursor-default select-none py-4 pl-5 pr-9 flex items-center',
            active ? 'bg-blue-200 text-white' : 'text-gray-700'
          )}
        >
          <PlusIcon className="h-5 w-5 mr-2" aria-hidden="true" />
          Add New Participant
        </span>
      )}
    </Combobox.Option>
  );
}

export default function ParticipantsCombobox({
  participants,
  handleNewParticipant,
  onSearch,
  disableAddNonParticipants = false,
}: ParticipantsComboboxProps) {
  const [query, setQuery] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    setError(false);
  }, [query]);

  const comboboxOptions = getOptionValue({
    participants,
    handleNewParticipant,
    disableAddNonParticipants,
    query,
    setError,
  });

  return (
    <Combobox as="div" onSubmit={() => handleNewParticipant({ id: Date.now(), email: query })}>
      {!disableAddNonParticipants && (
        <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">
          Invited
        </Combobox.Label>
      )}
      <div className="relative mt-2">
        <Combobox.Input
          className={twMerge(
            'outline-none block w-full rounded-md border-0 px-3 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 focus:ring-2 focus:ring-inset focus:ring-blue-500',
            error
              ? 'ring-red-600 border-red-400 text-red-500 focus:ring-red-500 focus:border-red-500'
              : 'focus:ring-blue-500'
          )}
          onChange={(event) => {
            setQuery(event.target.value);
            onSearch(event.target.value);
          }}
          displayValue={(participant: Participant) => participant?.name}
          placeholder="Invite team members"
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>
        <Combobox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {comboboxOptions}
        </Combobox.Options>
      </div>
      {error && <span className="text-xs text-red-500">Invalid email format.</span>}
    </Combobox>
  );
}
