import React from 'react';

import { twMerge } from 'tailwind-merge';

import { CheckCircleIcon } from '@heroicons/react/24/outline';

export interface CalendarCategoryItemProps {
  id: number;
  name: string;
  checked: boolean;
  calendar_category_group?: string;
  color: string;
  onCheckChange: (event, id) => void;
}

const CalendarCategoryItem = ({
  id,
  name,
  checked,
  onCheckChange,
  color,
}: CalendarCategoryItemProps) => {
  return (
    <div
      className={twMerge(
        'transition-all ease-linear duration-100 border flex items-center rounded-lg shadow-sm hover:shadow-md',
        checked ? `border-transparent` : 'border-gray-300'
      )}
      style={{ backgroundColor: checked ? color : 'transparent' }}
    >
      <label className="flex justify-between items-center cursor-pointer w-full px-4">
        <input
          type="checkbox"
          className="hidden"
          checked={checked}
          onChange={(e) => onCheckChange(e, id)}
        />
        <div className="text-gray-700 text-sm leading-5 font-medium p-2 whitespace-nowrap overflow-hidden select-none">
          {name}
        </div>
        {checked && <CheckCircleIcon className="text-gray-700 font-bold w-4 h-4" />}
      </label>
    </div>
  );
};

export default CalendarCategoryItem;
