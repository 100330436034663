import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['button', 'menu', 'item', 'input'];

  connect() {
    document.addEventListener('click', this.handleOutsideClick.bind(this));
    const item = this.itemTargets.find((item) => item.dataset.value === this.data.get('value'));
    this.buttonTarget.textContent = item ? item.textContent : 'Select an option';
  }

  disconnect() {
    document.removeEventListener('click', this.handleOutsideClick.bind(this));
  }

  select(event) {
    event.stopPropagation();
    this.buttonTarget.textContent = event.target.textContent;
    this.inputTarget.value = event.target.dataset.value;
    this.data.set('value', event.target.dataset.value);
    this.menuTarget.classList.add('hidden');
  }

  toggle(event) {
    event.stopPropagation();
    this.menuTarget.classList.toggle('hidden');
  }

  handleOutsideClick(event) {
    if (!this.element.contains(event.target)) {
      this.menuTarget.classList.add('hidden');
    }
  }
}
