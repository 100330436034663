import React from 'react';

export default function TagItem({ text }) {
  return (
    <div>
      <div className="inline-block bg-gray-50 border border-gray-300 rounded-lg px-2">
        <span className="text-sm leading-5 font-normal">{text}</span>
      </div>
    </div>
  );
}
