import React from 'react';

import { ParticipantImagePlaceholder } from './ParticipantImagePlaceholder';

interface ParticipantItemProps {
  name?: string;
  imageUrl?: string;
  email: string;
  role?: string;
}

const ParticipantItem: React.FC<ParticipantItemProps> = ({ imageUrl, name, email, role }) => {
  return (
    <div className="flex items-center gap-2">
      {imageUrl && imageUrl !== 'default_user_avatar.png' ? (
        <div
          className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8"
          style={{
            backgroundImage: `url(${imageUrl})`,
            backgroundSize: 'cover',
            borderRadius: '50%',
          }}
        >
          <span className="sr-only">Participant Image</span>
        </div>
      ) : (
        <ParticipantImagePlaceholder />
      )}
      <div className="font-medium overflow-hidden whitespace-nowrap text-overflow: ellipsis max-w-xs">
        <span className="text-sm leading-5 text-gray-700">{name || email}</span>
        {name && role && (
          <div className="text-xs leading-4 text-gray-500 overflow-hidden whitespace-nowrap text-overflow: ellipsis">
            {role}
          </div>
        )}
      </div>
    </div>
  );
};

export default ParticipantItem;
