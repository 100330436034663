import React from "react";

interface CheckboxFilterProps {
    filter: any;
    onChange: any;
}

export const CheckboxFilter: React.FC<CheckboxFilterProps> = ({ filter, onChange }) => {
    return (
        <>
            {filter.options.map((option, optionIdx) => (
                <div key={option.id} className="flex items-center">
                    <input
                        id={`filter-${filter.id}-${optionIdx}`}
                        name={`${filter.id}[]`}
                        defaultValue={option.id}
                        checked={(filter.value as string[]).includes(option.id)}
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 cursor-pointer"
                        onChange={() => onChange(filter, option)}

                    />
                    <label
                        htmlFor={`filter-${filter.id}-${optionIdx}`}
                        className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900 cursor-pointer"
                    >
                        {option.name}
                    </label>
                </div>
            ))}
        </>
    );
};

