import React from 'react';

export default function CircledCheckmarkIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M13.3599 8.35893C13.604 8.11486 13.604 7.71913 13.3599 7.47505C13.1158 7.23097 12.7201 7.23097 12.476 7.47505L8.7513 11.1998L7.52658 9.97505C7.2825 9.73097 6.88677 9.73097 6.64269 9.97505C6.39862 10.2191 6.39862 10.6149 6.64269 10.8589L8.30936 12.5256C8.55344 12.7697 8.94917 12.7697 9.19324 12.5256L13.3599 8.35893Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0013 1.04199C5.05375 1.04199 1.04297 5.05277 1.04297 10.0003C1.04297 14.9479 5.05375 18.9587 10.0013 18.9587C14.9489 18.9587 18.9596 14.9479 18.9596 10.0003C18.9596 5.05277 14.9489 1.04199 10.0013 1.04199ZM2.29297 10.0003C2.29297 5.74313 5.74411 2.29199 10.0013 2.29199C14.2585 2.29199 17.7096 5.74313 17.7096 10.0003C17.7096 14.2575 14.2585 17.7087 10.0013 17.7087C5.74411 17.7087 2.29297 14.2575 2.29297 10.0003Z"
        fill="white"
      />
    </svg>
  );
}
