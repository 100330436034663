import Rails from '@rails/ujs';

export default function convertToMp4Job(key, outputFilename) {
  return new Promise((resolve, reject) => {
    Rails.ajax({
      url: '/uppy/convert_video',
      type: 'POST',
      data: new URLSearchParams({ key, output_filename: outputFilename || key }).toString(),
      success: ({ Job }) => resolve({ Job }),
      error: () => reject(),
    });
  });
}
