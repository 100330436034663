import React, { useState, useCallback } from 'react';
import LottieMagicStick from '../sceneCards/LottieMagicStick';
import ConfirmationDialog from '../shared/ConfirmationDialog';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import api from '../../lib/api';

export default function GenerateAISceneDropdownItem({
  className,
  projectId,
  projectFolderId,
  documentId,
  sceneCardsFolderId,
}) {
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const navigateToSceneCards = useCallback(async () => {
    const { Turbo } = await import('@hotwired/turbo-rails');
    Turbo.visit(`/projects/${projectId}/project_folders/${sceneCardsFolderId}?needs_review=true`);
  }, [documentId]);

  const fetchScriptProcessingStatus = async (confirmDuplicate = false) => {
    try {
      const response = await api.post(
        `/projects/${projectId}/project_folders/${projectFolderId}/documents/${documentId}/process_script`,
        {
          confirmDuplicate,
        }
      );
      const data = response?.data;

      if (data?.status === 'processing') {
        setShowConfirmationDialog(false);
        navigateToSceneCards();
      } else if (data?.status === 'completed') {
        setShowConfirmationDialog(true);
      } else {
        setShowConfirmationDialog(false);
        navigateToSceneCards();
      }
    } catch (error) {
      console.error(error);
      setShowConfirmationDialog(false);
    }
  };

  const onGenerateAISceneCards = () => {
    fetchScriptProcessingStatus();
  };

  const handleConfirmAction = () => {
    fetchScriptProcessingStatus(true);
    setShowConfirmationDialog(false);
  };

  const handleCancelAction = () => {
    setShowConfirmationDialog(false);
  };

  return (
    <>
      <div className={className} onClick={onGenerateAISceneCards}>
        <LottieMagicStick className="mr-3 lg:mr-4" width={18} />
        Generate scene cards with AI
      </div>
      <ConfirmationDialog
        title="Are you sure?"
        description="If you have already generated scene cards, you might be creating duplicate scene cards."
        onConfirmLabel="Confirm"
        onCancelLabel="Cancel"
        onConfirmAction={handleConfirmAction}
        onCancelAction={handleCancelAction}
        confirmVariant={'primary'}
        isOpen={showConfirmationDialog}
        onClose={() => setShowConfirmationDialog(false)}
        icon={
          <div className="rounded-full p-3 bg-blue-100">
            <InformationCircleIcon className="w-6 h-6 text-blue-600 " />
          </div>
        }
      />
    </>
  );
}
