export const displayImperialHeight = (inches: number) => {
    const ft = Math.floor(inches / 12);
    const inch = inches % 12;
    return `${ft}'${inch}"`;
};

export function poundsToKg(pounds: number): number {
    return Math.ceil(pounds * 0.453592);
}


export function kgToPounds(kg: number): number {
    return Math.ceil(kg / 0.453592);
}

export function convertCmToInches(cm: number): number {
    return Math.ceil(cm / 2.54);
}

export const saveFiltersToLocalStorage = (key, filters) => {
    localStorage.setItem(key, JSON.stringify(filters));
}

export const fetchFiltersFromLocalStorage = (key, defaultValue) => {
    const storedFilters = localStorage.getItem(key);
    return storedFilters ? JSON.parse(storedFilters) : defaultValue;
}

export const clearFiltersFromLocalStorage = (key) => {
    localStorage.removeItem(key);
}