import React, { useMemo } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';

type Inputs = {
  id?: number;
  name: string;
  color: string;
};

export function CalendarCategorySettingsForm({
  initialValues,
  onSubmit,
  onCancel,
  isProductionCalendar,
}: {
  initialValues: Inputs;
  onSubmit: SubmitHandler<Inputs>;
  onCancel: () => void;
  isProductionCalendar: boolean;
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: initialValues,
  });

  const isUpdate = useMemo(() => initialValues.name.length > 0, [initialValues]);

  return (
    <form className="flex flex-col gap-4 p-4" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex justify-between items-center">
        <div className="text-lg">{isUpdate ? 'Edit Sub Calendar' : 'Add a Sub Calendar'}</div>
      </div>
      <hr className="mb-2" />
      <div>Name</div>
      <div className="rounded-md px-3 pb-1.5 pt-2.5 border">
        <label htmlFor="name" className="block text-xs font-medium text-gray-900">
          Name
        </label>
        <input
          type="text"
          name="name"
          id="name"
          disabled={isUpdate && isProductionCalendar}
          className="block w-full p-0 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 outline-none"
          placeholder="Sub Calendar Name"
          {...register('name')}
        />
      </div>
      <div>Color</div>
      <div className="rounded-md px-3 pb-1.5 pt-2.5 border">
        <label htmlFor="color" className="block text-xs font-medium text-gray-900">
          Color
        </label>
        <input
          type="color"
          name="color"
          id="color"
          className="block w-full p-0 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 outline-none"
          {...register('color')}
        />
      </div>
      <div className="flex gap-1 justify-end">
        <button
          type="button"
          onClick={onCancel}
          className="border py-2 px-4 rounded text-sm text-gray-700 hover:bg-gray-100 transition-all duration-100 ease-in"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="py-2 px-4 rounded text-sm bg-blue-500 text-white hover:bg-blue-600 transition-all duration-100 ease-in"
        >
          Save
        </button>
      </div>
    </form>
  );
}
