import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['measureInput', 'measureButton', 'dropdown', 'measureName'];

  connect() {
    this.toggleMeasureSystem(this.measureInputTarget.value);
    document.addEventListener('click', this.handleOutsideClick.bind(this));
  }

  disconnect() {
    document.removeEventListener('click', this.handleOutsideClick.bind(this));
  }

  toggleDropdown(event) {
    event.stopPropagation();
    this.dropdownTarget.classList.toggle('hidden');
  }

  setMeasure(event) {
    event.preventDefault();
    const system = event.currentTarget.dataset.value;
    const measureName = event.currentTarget.textContent.trim();
    this.measureInputTarget.value = system;
    this.measureNameTarget.textContent = measureName;
    this.toggleMeasureSystem(system);
    this.closeDropdown();
  }

  closeDropdown() {
    this.dropdownTarget.classList.add('hidden');
  }

  handleOutsideClick(event) {
    if (!this.element.contains(event.target)) {
      this.closeDropdown();
    }
  }

  toggleMeasureSystem(system) {
    const metricForm = document.getElementById('metric_form');
    const imperialForm = document.getElementById('imperial_form');

    if (system === 'metric') {
      metricForm.classList.remove('hidden');
      imperialForm.classList.add('hidden');
      metricForm.querySelectorAll('input').forEach((input) => (input.required = true));
      imperialForm.querySelectorAll('input').forEach((input) => (input.required = false));
    } else if (system === 'imperial') {
      metricForm.classList.add('hidden');
      imperialForm.classList.remove('hidden');
      metricForm.querySelectorAll('input').forEach((input) => (input.required = false));
      imperialForm.querySelectorAll('input').forEach((input) => (input.required = true));
    }
  }
}
