import { Controller } from 'stimulus';
import Rails from '@rails/ujs';
import Sortable from 'sortablejs';

export default class extends Controller {
  connect() {
    if (/Mobile|webOS|iPhone|iPad|iPod/i.test(navigator.userAgent)) return;

    this.sortable = Sortable.create(this.element, {
      onEnd: this.updatePosition.bind(this),
      filter: '.no-drag',
    });
  }

  updatePosition(event) {
    if (!event.item.dataset.url) return;

    const data = new FormData();
    data.append('position', event.newIndex + 1);

    Rails.ajax({
      url: event.item.dataset.url,
      type: 'PATCH',
      data,
    });
  }
}
