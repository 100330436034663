import React from 'react';

import { twMerge } from 'tailwind-merge';

interface Props {
  children?: React.ReactNode;
  onClick: (event: React.MouseEvent) => void;
  variant?: 'primary' | 'secondary' | 'danger';
  className?: string;
  type?: 'button' | 'submit';
}

export default function Button({ children, onClick, variant = 'primary', className, type }: Props) {
  const variantToClass = {
    primary: 'bg-blue-500 text-white rounded-md px-4 py-2 shadow-sm flex-1 hover:bg-blue-600',
    danger: 'bg-red-500 text-white rounded-md px-4 py-2 shadow-sm flex-1 hover:bg-red-600',
    secondary:
      'border border-gray-300 bg-white text-gray-700 rounded-md py-1.5 px-3 flex-1 shadow-sm hover:text-gray-500',
  };

  function handleClick(event) {
    event?.preventDefault();
    onClick(event);
  }

  return (
    <button
      type={type}
      onClick={handleClick}
      className={twMerge(
        'text-sm leading-5 font-medium rounded-md py-1.5 px-3 flex-1',
        variantToClass[variant],
        className
      )}
    >
      {children}
    </button>
  );
}
