import React from 'react';

export default function PencilIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7481 1.9929C13.016 0.725022 15.0716 0.725022 16.3395 1.9929C17.6074 3.26078 17.6074 5.31642 16.3395 6.5843L10.0111 12.9127C9.65446 13.2694 9.43665 13.4872 9.19388 13.6765C8.90785 13.8996 8.59838 14.0909 8.27092 14.247C7.99299 14.3794 7.70073 14.4768 7.22218 14.6363L4.99497 15.3787L4.46025 15.557C3.98163 15.7165 3.45394 15.5919 3.09719 15.2352C2.74045 14.8784 2.61588 14.3507 2.77542 13.8721L3.69606 11.1102C3.85555 10.6316 3.95295 10.3394 4.08541 10.0615C4.24147 9.734 4.43273 9.42452 4.65583 9.13849C4.84518 8.89572 5.06303 8.67791 5.41974 8.32123L11.7481 1.9929ZM4.96614 14.0707L4.26166 13.3662L4.86868 11.5452C5.04542 11.015 5.11841 10.7994 5.21381 10.5992C5.33081 10.3537 5.47421 10.1217 5.64147 9.90727C5.77784 9.73243 5.93802 9.57072 6.33321 9.17553L11.2424 4.26639C11.445 4.77471 11.7878 5.38823 12.366 5.96638C12.9441 6.54454 13.5577 6.88739 14.066 7.09002L9.15684 11.9992C8.76165 12.3944 8.59995 12.5545 8.4251 12.6909C8.21067 12.8582 7.97864 13.0016 7.73315 13.1186C7.53298 13.214 7.3174 13.287 6.78719 13.4637L4.96614 14.0707ZM15.062 6.094C14.9588 6.07134 14.8298 6.03726 14.6831 5.98636C14.2798 5.84643 13.7492 5.58181 13.2499 5.0825C12.7506 4.58319 12.4859 4.05262 12.346 3.64927C12.2951 3.50258 12.261 3.37355 12.2384 3.27036L12.632 2.87679C13.4117 2.09706 14.6759 2.09706 15.4556 2.87679C16.2353 3.65651 16.2353 4.9207 15.4556 5.70042L15.062 6.094ZM2.70705 18.3337C2.70705 17.9885 2.98687 17.7087 3.33205 17.7087H16.6654V18.9587H3.33205C2.98687 18.9587 2.70705 18.6788 2.70705 18.3337Z"
        fill="#A1A1AA"
      />
    </svg>
  );
}
