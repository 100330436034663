import { FileChecksum } from '@rails/activestorage/src/file_checksum';

function calculateChecksum(file) {
  return new Promise((resolve, reject) => {
    FileChecksum.create(file, (error, checksum) => {
      if (error) {
        reject(error);
        return;
      }

      resolve(checksum);
    });
  });
}

export function getFileMetadata(file) {
  return new Promise((resolve) => {
    calculateChecksum(file.data).then((checksum) => {
      resolve({
        checksum,
        filename: file.data.name,
        key: file.s3Multipart.key,
        content_type: file.data.type,
        byte_size: file.data.size,
      });
    });
  });
}
