import { ProjectMember } from './Calendar';

export function filterProjectMembersBySearchTerm(
  members: ProjectMember[],
  searchTerm: string
): ProjectMember[] {
  if (!members || !searchTerm) {
    return [];
  }

  const lowerCaseSearchTerm = searchTerm.toLowerCase();
  return members.filter((member) => {
    const searchableFields = ['name', 'email', 'role'];
    return searchableFields.some((field) => {
      const fieldValue = member[field];
      return fieldValue?.toLowerCase().startsWith(lowerCaseSearchTerm);
    });
  });
}
