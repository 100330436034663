import axios from 'axios';

if (typeof window !== 'undefined') {
  const csrfToken = (document.querySelector("[name='csrf-token']") as HTMLMetaElement).content;
  axios.defaults.headers.common['X-CSRF-Token'] = csrfToken;
}

export const api = axios.create({
  baseURL: '/',
  withCredentials: true,
});

export default api;
