import React from 'react';

import DropdownMenu from '../../shared/DropdownMenu';

export default function CalendarCategoriesSettingsItem({ name, color = '#FF0000', actions }) {
  return (
    <div>
      <div className="bg-white shadow-sm rounded-lg p-3 px-4 py-5 sm:p-4 mb-2 flex items-center justify-between">
        <div className="flex justify-between w-full items-center">
          <div className="flex items-center">
            <div
              className={`w-5 h-5 rounded flex items-center justify-center`}
              style={{ backgroundColor: color }}
            ></div>
            <div className="text-gray-700 text-sm leading-5 font-medium px-2 py-2">{name}</div>
          </div>
          <DropdownMenu items={actions} />
        </div>
      </div>
    </div>
  );
}
