import { Controller } from 'stimulus';

export default class extends Controller {
  send(event) {
    window.webkit?.messageHandlers?.nativeApp?.postMessage(
      JSON.stringify({
        type: 'link',
        payload: event.currentTarget.href,
      })
    );
  }
}
