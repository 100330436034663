import React from 'react';
import LottieMagicStick from './LottieMagicStick';

export default function SceneCardsAIInfoCard({ title, description }) {
  return (
    <article className="flex items-center shadow bg-white flex-col px-5 py-20 rounded-2xl">
      <section className="flex items-center flex-col">
        <LottieMagicStick width={48} height={48} />
        <h2 className="text-gray-700 text-lg leading-6 font-medium mt-1">{title}</h2>
        <p className="text-gray-500 text-sm leading-5 font-normal">{description}</p>
      </section>
    </article>
  );
}
