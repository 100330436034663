import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';

import keyBy from 'lodash/keyBy';

import {
  PlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';

import api from '../../lib/api';
import { filterProjectMembersBySearchTerm } from '../calendar/eventFormUtils';
import ParticipantCardItem from '../search/ParticipantCardItem';
import ParticipantsCombobox from '../search/ParticipantsCombobox';
import Dialog from './Dialog';
import { IconButton } from './IconButton';

function SceneCardsPermissionForm({
  projectId,
  projectFolderId,
  sceneCardId,
  currentUser,
  handleHideDialog,
  attachedUsers,
  projectMembers,
  setAttachedUsers,
}) {
  const [searchResults, setSearchResults] = useState([]);

  const handleSearchForParticipants = useCallback(
    (searchTerm) => {
      const projectMembersWithoutCurrentUser = projectMembers.filter(
        ({ email }) =>
          email !== currentUser.email && attachedUsers.every((user) => user.email !== email)
      );
      const filteredMembers = filterProjectMembersBySearchTerm(
        projectMembersWithoutCurrentUser,
        searchTerm
      );
      const uniqueFilteredMembers = filteredMembers.filter(
        ({ email }) => !attachedUsers.some((participant) => participant.email === email)
      ); // remove already selected participants
      setSearchResults(uniqueFilteredMembers);
    },
    [projectMembers, attachedUsers]
  );

  const handleNewParticipant = async (participant) => {
    if (!participant) return;
    const user = projectMembers.find((projectMember) => projectMember?.id === participant?.id);
    setAttachedUsers([user, ...attachedUsers]);
    const result = await api.post(
      `/projects/${projectId}/project_folders/${projectFolderId}/scene_cards/${sceneCardId}/attach_user`,
      {
        user_id: participant.id,
      }
    );
  };

  const handleRemoveParticipant = async (userId) => {
    if (!userId) return;
    setAttachedUsers(attachedUsers.filter((user) => user.id !== userId));
    const result = await api.post(
      `/projects/${projectId}/project_folders/${projectFolderId}/scene_cards/${sceneCardId}/detach_user`,
      {
        user_id: userId,
      }
    );
    if (result.status !== 200) return;
  };

  return (
    <div className="h-96 overflow-y-auto px-8 p-6">
      <div className="flex items-center justify-between">
        <div className="text-gray-700 leading-6 font-medium">Scene Card Permissions</div>
        <IconButton onClick={handleHideDialog}>
          <XMarkIcon className="h-6 w-6 text-gray-500" />
        </IconButton>
      </div>
      <ParticipantsCombobox
        participants={searchResults}
        handleNewParticipant={handleNewParticipant}
        onSearch={handleSearchForParticipants}
        disableAddNonParticipants={true}
      />
      <div className="text-sm text-gray-700 leading-6 font-medium mt-2">Read Access</div>
      <div className="flex flex-col gap-2 my-2">
        {attachedUsers.length > 0 ? (
          attachedUsers.map((rawUser) => {
            const user = projectMembers.find((projectMember) => projectMember?.id === rawUser?.id);
            if (!user) return null;
            return (
              <ParticipantCardItem
                key={user.id}
                name={user.name}
                role={user.role}
                imageUrl={user.imageUrl}
                email={user.email}
                onClose={() => handleRemoveParticipant(user.id)}
              />
            );
          })
        ) : (
          <div className="text-gray-700 text-sm text-center">
            Add team members that should have read access to this scene card.
          </div>
        )}
      </div>
    </div>
  );
}

function AvatarsButton({ projectMembers, attachedUsers, onClick }) {
  const projectMembersById = keyBy(projectMembers, 'id');
  const attachedUsersList = attachedUsers?.slice().reverse().slice(0, 5);
  return (
    <div className="flex -space-x-2 overflow-hidden">
      {attachedUsersList.map((user) => {
        const member = projectMembersById[user.id];
        const hasImage = !!user?.avatar_crop_height;
        if (!hasImage)
          return (
            <span
              key={user.id}
              className="flex-shrink-0 inline-block h-10 w-10 overflow-hidden rounded-full bg-gray-100"
            >
              <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>
            </span>
          );
        return (
          <img
            key={user.id}
            className="h-10 w-10 rounded-full ring-2 ring-white flex-shrink-0"
            src={member?.imageUrl}
            alt=""
          />
        );
      })}
      {!attachedUsersList?.length ? (
        <span
          key="placeholder"
          className="flex-shrink-0 inline-block h-10 w-10 overflow-hidden rounded-full bg-gray-100"
        >
          <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
          </svg>
        </span>
      ) : null}
      <IconButton onClick={onClick}>
        <div className="flex-shrink-0 inline-flex items-center justify-center h-10 w-10 rounded-full ring-2 ring-white bg-blue-500 hover:bg-blue-600">
          <PlusIcon className="h-5 w-5 text-white" />
        </div>
      </IconButton>
    </div>
  );
}

export default function SceneCardsPermissionsDialog({
  projectId,
  projectFolderId,
  sceneCardId,
  currentUser,
}) {
  const [showDialog, setShowDialog] = useState(false);
  const [projectMembers, setProjectMembers] = useState([]);
  const [attachedUsers, setAttachedUsers] = useState([]);

  const handleShowDialog = () => {
    setShowDialog(true);
  };

  const handleHideDialog = () => {
    setShowDialog(false);
  };

  useEffect(() => {
    async function fetchProjectMembers() {
      try {
        if (!projectId) return;
        const response = await api.get(`/projects/${projectId}/participants`);
        const { users } = response.data;

        setProjectMembers(users);
      } catch (error) {}
    }
    async function fetchAttachedUsers() {
      try {
        const response = await api.get(
          `/projects/${projectId}/project_folders/${projectFolderId}/scene_cards/${sceneCardId}/users`
        );
        const { users } = response.data;
        setAttachedUsers(users);
      } catch (error) {}
    }
    fetchProjectMembers();
    fetchAttachedUsers();
  }, [projectId]);

  return (
    <div className="overflow-hidden">
      <AvatarsButton
        projectMembers={projectMembers}
        attachedUsers={attachedUsers}
        onClick={handleShowDialog}
      />
      <Dialog open={showDialog} onClose={handleHideDialog}>
        <SceneCardsPermissionForm
          projectId={projectId}
          projectFolderId={projectFolderId}
          sceneCardId={sceneCardId}
          currentUser={currentUser}
          handleHideDialog={handleHideDialog}
          attachedUsers={attachedUsers}
          setAttachedUsers={setAttachedUsers}
          projectMembers={projectMembers}
        />
      </Dialog>
    </div>
  );
}
