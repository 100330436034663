import React from 'react';

export default function MagicStick(props) {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5289 1.77027C13.8015 1.07658 14.7814 1.07657 15.054 1.77027L15.4107 2.67795L16.3145 3.03575C17.0057 3.30934 17.0057 4.28928 16.3145 4.56287L15.4107 4.92067L15.054 5.82835C14.7814 6.52204 13.8015 6.52205 13.5289 5.82835L13.1722 4.92067L12.2684 4.56287C11.5772 4.28928 11.5772 3.30934 12.2684 3.03575L13.1722 2.67795L13.5289 1.77027ZM14.2915 2.10824L14.6371 2.98778C14.7201 3.19909 14.887 3.36689 15.0985 3.45061L15.9793 3.79931L15.0985 4.14801C14.887 4.23173 14.7201 4.39953 14.6371 4.61084L14.2915 5.49038L13.9458 4.61084C13.8628 4.39953 13.6959 4.23173 13.4844 4.14801L12.6036 3.79931L13.4844 3.45061C13.6959 3.36689 13.8628 3.19909 13.9458 2.98778L14.2915 2.10824Z"
        fill="#60A5FA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.26183 2.76183C4.44427 1.57939 6.36139 1.57939 7.54383 2.76183L17.7382 12.9562C18.9206 14.1386 18.9206 16.0557 17.7382 17.2382C16.5557 18.4206 14.6386 18.4206 13.4562 17.2382L3.26183 7.04383C2.07939 5.86139 2.07939 3.94427 3.26183 2.76183ZM6.65994 3.64571C5.96566 2.95143 4.84 2.95143 4.14571 3.64571C3.45143 4.34 3.45143 5.46566 4.14571 6.15994L5.46761 7.48184L7.98184 4.96761L6.65994 3.64571ZM14.3401 16.3543L6.3515 8.36573L8.86573 5.8515L16.8543 13.8401C17.5486 14.5343 17.5486 15.66 16.8543 16.3543C16.16 17.0486 15.0343 17.0486 14.3401 16.3543Z"
        fill="#60A5FA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.2766 7.4555C18.004 6.7618 17.0241 6.7618 16.7515 7.4555L16.6227 7.78322L16.2967 7.9123C15.6055 8.18588 15.6055 9.16582 16.2967 9.43941L16.6227 9.56849L16.7515 9.89621C17.0241 10.5899 18.004 10.5899 18.2766 9.89621L18.4054 9.56849L18.7315 9.43941C19.4226 9.16582 19.4226 8.18588 18.7315 7.9123L18.4054 7.78322L18.2766 7.4555ZM17.5141 7.79346L17.3964 8.09305C17.3133 8.30437 17.1464 8.47216 16.935 8.55588L16.6319 8.67585L16.935 8.79583C17.1464 8.87954 17.3133 9.04734 17.3964 9.25866L17.5141 9.55824L17.6318 9.25866C17.7148 9.04734 17.8817 8.87954 18.0932 8.79583L18.3963 8.67585L18.0932 8.55588C17.8817 8.47216 17.7148 8.30437 17.6318 8.09305L17.5141 7.79346Z"
        fill="#60A5FA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.38986 12.6036C4.66245 11.9099 5.6424 11.9099 5.91498 12.6036L6.04376 12.9313L6.36982 13.0604C7.06096 13.334 7.06096 14.3139 6.36982 14.5875L6.04376 14.7166L5.91498 15.0443C5.6424 15.738 4.66245 15.738 4.38986 15.0443L4.26109 14.7166L3.93502 14.5875C3.24388 14.3139 3.24388 13.334 3.93502 13.0604L4.26109 12.9313L4.38986 12.6036ZM5.0347 13.2412L5.15242 12.9416L5.27014 13.2412C5.35318 13.4525 5.52005 13.6203 5.73154 13.704L6.03462 13.824L5.73154 13.9439C5.52005 14.0277 5.35318 14.1955 5.27014 14.4068L5.15242 14.7064L5.0347 14.4068C4.95167 14.1955 4.78479 14.0277 4.5733 13.9439L4.27022 13.824L4.5733 13.704C4.78479 13.6203 4.95167 13.4525 5.0347 13.2412Z"
        fill="#60A5FA"
      />
    </svg>
  );
}
