import { Controller } from 'stimulus';

export default class BaseNewSceneCardDropdown extends Controller {
  static targets = ['button', 'menu'];

  connect() {
    const currentValue = this.element.querySelector('input').value;
    const currentText = currentValue.charAt(0).toUpperCase() + currentValue.slice(1);
    this.buttonTarget.textContent = currentText;
    this.element.querySelector('input').value = currentValue;
    document.addEventListener('click', this.clickOutside.bind(this));
  }

  disconnect() {
    document.removeEventListener('click', this.clickOutside.bind(this));
  }

  toggle() {
    this.menuTarget.classList.toggle('hidden');
  }

  select(event) {
    event.preventDefault();
    this.buttonTarget.textContent = event.target.textContent;
    this.menuTarget.classList.add('hidden');
    this.element.querySelector('input').value = event.target.dataset.value;
  }

  clickOutside(event) {
    if (!this.element.contains(event.target) && !this.menuTarget.classList.contains('hidden')) {
      this.menuTarget.classList.add('hidden');
    }
  }
}
