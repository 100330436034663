import React, { useState, useEffect } from 'react';
import UserFilter from './UserFilter';

export interface User {
  id: number;
  uuid: string;
  firstName: string;
  lastName: string;
  country: string;
  ethnicities: string[];
  gender: string;
  height: number;
  weight: number;
  avatarUrl: string;
  croppedAvatarUrl: string;
  measure_system: 'imperial' | 'metric';
}

interface CurrentUser extends User {
  super_admin: boolean;
}

interface DirectoryProps {
  users: User[];
  currentUser: CurrentUser;
  measurementRanges: {
    height: {
      min: number;
      max: number;
    };
    weight: {
      min: number;
      max: number;
    };
  };
}

export default function Directory({ users, currentUser, measurementRanges }: DirectoryProps) {
  const [filteredUsers, setFilteredUsers] = useState<User[]>(users);
  const isSuperAdmin = currentUser.super_admin;

  return (
    <div className="w-full flex flex-1 mx-auto px-4 sm:px-6 lg:px-8 pt-10 pb-10">
      <div className="w-full max-w-6xl">
        <span className="text-3xl leading-9 font-semibold text-gray-900">Member Directory</span>
        <hr className="mt-5 mb-5" />
        <UserFilter
          users={users}
          measurementRanges={measurementRanges}
          currentUser={currentUser}
          onFilter={setFilteredUsers}
        />
        {isSuperAdmin && (
          <label className="block text-sm font-medium text-gray-700">{`Showing ${filteredUsers.length} of ${users.length} members`}</label>
        )}
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4 mt-4">
          {filteredUsers.map((user) => (
            <div className="flex-1 bg-white shadow-lg rounded-b-lg divide-y divide-gray-200 project-row">
              <a href={`/users/${user.uuid}?from=directory`} className="block">
                <div className="user-avatar w-full h-0 overflow-hidden rounded-t-lg bg-gray-200 relative">
                  <img
                    className="absolute top-0 d-block ml-auto mr-auto w-full h-full object-cover"
                    src={user.croppedAvatarUrl}
                    alt=""
                  />
                </div>
                <div className="flex justify-between px-4 py-4 sm:px-6 mt-auto">
                  {user.firstName} {user.lastName}
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
