import React from 'react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';

export default function Breadcrumb({ pages }) {
  return (
    <>
      <hr />
      <nav className="flex border-b px-8 py-3 border-gray-200 bg-white" aria-label="Breadcrumb">
        <ol role="list" className="flex items-center space-x-4">
          {pages.map((page, index) => (
            <li key={page.name}>
              <div className="flex items-center">
                {index !== 0 && (
                  <ChevronRightIcon
                    className="h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                )}
                <a
                  href={page.href}
                  className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  aria-current={page.current ? 'page' : undefined}
                >
                  {page.name}
                </a>
              </div>
            </li>
          ))}
        </ol>
      </nav>
    </>
  );
}
