import orderBy from 'lodash/orderBy';

export function groupByCategoryGroup(calendarCategories) {
  const result = {};
  const sorted = orderBy(calendarCategories, ['calendar_category_group'], ['desc']);

  sorted.forEach((calendarCategory) => {
    const group = calendarCategory.calendar_category_group;
    if (result[group]) {
      result[group] = [...result[group], calendarCategory];
    } else {
      result[group] = [calendarCategory];
    }
  });

  return result;
}