import React from 'react';
import Lottie from 'react-lottie';

export default function LottieMagicStick(props) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    path: 'https://lottie.host/5a24a123-c6a2-44be-879a-4c7baae70c82/4pzvb3F35p.json',
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div className={props.className} style={{ pointerEvents: 'none' }}>
      <Lottie
        options={props.options ?? defaultOptions}
        isStopped={false}
        isPaused={false}
        width={props.width}
        height={props.height}
      />
    </div>
  );
}
