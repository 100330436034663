import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.addDismissIcon();
  }

  addDismissIcon() {
    const dismissContainer = document.getElementById('dismissContainer');

    if (dismissContainer) {
      const dismissButton = document.createElement('button');
      dismissButton.className = 'hidden md:block';
      dismissButton.setAttribute('data-action', 'flash-messages#dismiss');
      dismissButton.innerHTML = `
        <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z' fill='#ECFDF5'/>
          <path d='M8.33333 11.6667L10 10M10 10L11.6667 8.33333M10 10L8.33333 8.33333M10 10L11.6667 11.6667M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z' stroke='#065F46' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/>
        </svg>
      `;

      dismissContainer.appendChild(dismissButton);
    }
  }

  dismiss() {
    document.querySelector('.flash-message').remove();
  }
}
