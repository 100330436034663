import Rails from '@rails/ujs';
import { Controller } from 'stimulus';
import Uppy from '@uppy/core';
import DragDrop from '@uppy/drag-drop';
import FileInput from '@uppy/file-input';
import ProgressBar from '@uppy/progress-bar';
import AwsS3Multipart from '@uppy/aws-s3-multipart';

import { getFileMetadata } from '../lib/getFileMetadata';
import convertToMp4Job from '../lib/convertToMp4Job';
import waitJobComplete from '../lib/waitJobComplete';
import buildFormData from '../lib/buildFormData';

export default class extends Controller {
  static get values() {
    return {
      dragDrop: Boolean,
      paramName: String,
      successUrl: String,
      acceptValues: String,
      allowBigFiles: Boolean,
      acceptValuesLabel: String,
    };
  }

  static get targets() {
    return ['input', 'spinner'];
  }

  inputName = `${this.paramNameValue}[file]`;

  fileInputConfig = {
    target: this.inputTarget,
    inputName: this.inputName,
    locale: {
      strings: {
        chooseFiles: 'Choose a file',
      },
    },
  };

  dragDropConfig = {
    target: this.inputTarget,
    inputName: this.inputName,
    locale: {
      strings: {
        dropHereOr: '%{browse} or drag and drop',
        browse: 'Upload a file',
      },
    },
    note: `${this.acceptValuesLabelValue || ''}`,
  };

  connect() {
    if (document.getElementsByClassName('uppy-Root').length) return;

    const restrictions = {
      minNumberOfFiles: 1,
      maxNumberOfFiles: 1,
    };

    if (this.acceptValuesValue) restrictions.allowedFileTypes = this.acceptValuesValue.split(',');

    this.uppy = new Uppy({
      debug: true,
      restrictions,
      autoProceed: this.dragDropValue,
    })
      .use(
        this.dragDropValue ? DragDrop : FileInput,
        this.dragDropValue ? this.dragDropConfig : this.fileInputConfig
      )
      .use(ProgressBar, {
        target: this.inputTarget,
      })
      .use(AwsS3Multipart, {
        companionUrl: '/',
      });

    if (this.allowBigFilesValue) {
      this.addPreviewAlert();
    }

    this.uppy.on('file-added', (file) => {
      if (this.dragDropValue) {
        this.addDragDropSpinner();
      } else {
        this.addFileInputName(file.data.name);
      }
    });

    this.uppy.on('upload', () => {
      this.showLoading();
    });

    this.uppy.on('upload-success', async (file) => {
      const metadata = await getFileMetadata(file);
      const data = buildFormData(metadata);

      Rails.ajax({
        url: '/uppy/create_blob',
        type: 'POST',
        data,
        success: async (signedId) => {
          try {
            await this.handleFileConvert(file);
            this.handleSubmit(file, signedId);
          } catch (err) {
            console.error(err);
            Turbo.visit(window.location.href);
          } finally {
            this.hideLoading();
          }
        },
        error: () => Turbo.visit(window.location.href),
      });
    });
  }

  upload(e) {
    e.preventDefault();
    this.uppy.upload();
  }

  handleSubmit(file, signedId) {
    const data = buildFormData({ [this.inputName]: signedId, name: file.data.name });
    Rails.ajax({
      url: this.element.action,
      type: 'POST',
      data,
      success: () => Turbo.visit(this.successUrlValue || window.location.href),
      error: () => Turbo.visit(window.location.href),
    });
  }

  async handleFileConvert(file) {
    if (!file.type.includes('video')) return;

    if (file.type.includes('mp4')) {
      const { Job } = await convertToMp4Job(
        file.s3Multipart.key,
        file.s3Multipart.key.replace('.mp4', '-converted')
      );
      await waitJobComplete(Job.id);
    } else {
      const { Job } = await convertToMp4Job(file.s3Multipart.key);
      await waitJobComplete(Job.id);
    }
  }

  showLoading() {
    for (const child of this.element.elements) {
      child.disabled = true;
    }

    if (!this.hasSpinnerTarget) return;

    this.spinnerTarget.classList.remove('hidden');
    this.element.classList.add('cursor-wait');
  }

  hideLoading() {
    for (const child of this.element.elements) {
      child.disabled = false;
    }

    if (!this.hasSpinnerTarget) return;

    this.spinnerTarget.classList.add('hidden');
    this.element.classList.remove('cursor-wait');
  }

  addDragDropSpinner() {
    const [browserElem] = document.getElementsByClassName('uppy-DragDrop-browse') || [];
    if (!browserElem) return;

    browserElem.innerHTML = `
      <svg data-uppy-target="spinner" class="hidden animate-spin mr-3 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>`;
  }

  addFileInputName(name) {
    const [fileInputBtn] = document.getElementsByClassName('uppy-FileInput-btn') || [];
    if (!fileInputBtn) return;

    fileInputBtn.insertAdjacentHTML(
      'afterend',
      `
      <span class="w-full text-gray-900 text-sm font-medium overflow-hidden no-wrap">
        ${name}
      </span>`
    );
  }

  addPreviewAlert() {
    const [noteElem] = document.getElementsByClassName('uppy-DragDrop-note') || [];
    const [dragDropInnerElem] = document.getElementsByClassName('uppy-DragDrop-inner') || [];
    if (!noteElem || !dragDropInnerElem) return;
    const alertElem = noteElem.cloneNode(true);
    alertElem.innerHTML =
      'Note: When uploading a large file, it may take up to a few minutes for the thumbnail to be generated';
    dragDropInnerElem.appendChild(alertElem);
  }
}
