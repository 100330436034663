import React from 'react';
import TagItem from './TagItem';

export interface SceneCardActionProps {
  feature: string;
  text: string;
  bgColor?: string | null;
}

export default function ActionItem({ text, feature, bgColor }: SceneCardActionProps) {
  return (
    <div className={`${bgColor || 'bg-gray-50'} flex flex-col p-4 rounded-lg gap-4`}>
      <TagItem text={text} />
      <span className="text-gray-900 text-sm leading-5 font-normal">{feature}</span>
    </div>
  );
}
