import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { format } from 'date-fns';
import debounce from 'lodash.debounce';
import { SubmitHandler, useForm } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { Switch } from '@headlessui/react';
import { ExclamationTriangleIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/outline';

import { api } from '../../lib/api';
import ParticipantCardItem from '../search/ParticipantCardItem';
import ParticipantsCombobox from '../search/ParticipantsCombobox';
import Button from '../shared/Button';
import ConfirmationDialog from '../shared/ConfirmationDialog';
import { IconButton } from '../shared/IconButton';
import SelectCalendarCategory from '../shared/SelectCalendarCategory';
import SelectSceneCardCombobox from '../shared/SelectSceneCardCombobox';
import { showToast } from '../shared/Toast';
import {
  CalendarCategory,
  CalendarEvent,
  CurrentUser,
  ProjectMember,
  ProjectRole,
  SceneCard,
} from './Calendar';
import EventFormField from './EventFormField';
import { filterProjectMembersBySearchTerm } from './eventFormUtils';
import { eventWithCategories, formatDates, formatWhen } from './eventUtils';

export type EventFormInput = {
  title: string;
  description: string;
  location: string;
  participants?: string[];
  startTime: string;
  endTime: string;
  startDate: string;
  endDate: string;
  calendarCategoryId: number;
  allDay: boolean;
  sceneCardId?: string;
};

export interface Participant {
  id?: number;
  name?: string;
  email: string;
  role?: string;
  imageUrl?: string;
}

interface EventFormProps {
  selectedEvent: CalendarEvent;
  setSelectedEvent: React.Dispatch<React.SetStateAction<CalendarEvent>>;
  calendarId: string;
  selectedDate: { start: Date; end: Date };
  setEvents: React.Dispatch<React.SetStateAction<CalendarEvent[]>>;
  calendarCategories: CalendarCategory[];
  currentUser: CurrentUser;
  setMode: (mode: 'view' | 'edit') => void;
  projectSceneCards: SceneCard[];
  projectMembers: ProjectMember[];
  projectMembersByEmail: Record<string, ProjectMember>;
  setIsEventInfoOpen: React.Dispatch<React.SetStateAction<boolean>>;
  allDaySelected: boolean;
  setAllDaySelected: React.Dispatch<React.SetStateAction<boolean>>;
  projectRole: ProjectRole;
}

export function FormGroupTitle({ title }) {
  return <div className="text-gray-700 leading-6 font-medium">{title}</div>;
}

export function FormLabel({ label }) {
  return <label className="mb-2 block text-sm leading-5 font-medium text-gray-700">{label}</label>;
}

export default function Toggle({ value, onChange }) {
  return (
    <Switch
      checked={value}
      onChange={onChange}
      className={twMerge(
        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2',
        value ? 'bg-blue-500' : 'bg-gray-200'
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={twMerge(
          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
          value ? 'translate-x-5' : 'translate-x-0'
        )}
      />
    </Switch>
  );
}

export function EventForm({
  selectedEvent,
  setSelectedEvent,
  calendarId,
  projectMembers,
  projectMembersByEmail,
  selectedDate,
  setEvents,
  calendarCategories,
  currentUser,
  setMode,
  setIsEventInfoOpen,
  allDaySelected,
  projectSceneCards,
  setAllDaySelected,
  projectRole,
}: EventFormProps) {
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [showDiscardConfirmationModal, setShowDiscardConfirmationModal] = useState(false);
  const [selectedParticipants, setSelectedParticipants] = useState<Participant[]>([]);
  const [searchResults, setSearchResults] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const hasFormChangedRef = useRef(false);

  const filteredCalendarCategories = useMemo(() => {
    if (projectRole === 'wardrobe' || projectRole === 'hair_and_makeup') {
      return calendarCategories.filter(
        (category) =>
          category.name === 'Hair & Makeup Fittings' || category.name === 'Costume Fittings'
      );
    }
    return calendarCategories;
  }, [calendarCategories, projectRole]);

  const { register, handleSubmit, setValue, reset, formState, watch, setFocus, control } =
    useForm<EventFormInput>({
      defaultValues: {
        calendarCategoryId: filteredCalendarCategories[0]?.id,
        allDay: allDaySelected,
        title: '',
        startTime: '',
        endTime: '',
        description: '',
        startDate: '',
        endDate: '',
        location: '',
        sceneCardId: '',
        ...selectedEvent,
      },
    });
  const { errors, dirtyFields } = formState;

  const isUpdating = useMemo(() => !!selectedEvent, [selectedEvent]);
  const isAllDay = watch('allDay');

  useEffect(() => {
    setFocus('title');
  }, []);

  const resetForm = () => {
    reset({
      title: '',
      calendarCategoryId: filteredCalendarCategories[0].id,
      startTime: '',
      endTime: '',
      description: '',
      startDate: '',
      endDate: '',
      participants: [],
      location: '',
      allDay: false,
      sceneCardId: '',
    });
    setSelectedParticipants([]);
    setMode('view');
  };

  useEffect(() => {
    if (!selectedEvent) return;
    reset();

    const { title, description, location, participants, start, end, allDay } = selectedEvent;
    const { parsedStart, parsedEnd } = formatDates({ start, end, allDay });

    const startDate = parsedStart ? format(parsedStart, 'yyyy-MM-dd') : '';
    const endDate = parsedEnd ? format(parsedEnd, 'yyyy-MM-dd') : '';
    const startTime = parsedStart ? format(parsedStart, 'HH:mm') : '';
    const endTime = parsedEnd ? format(parsedEnd, 'HH:mm') : '';
    const sceneCardId = selectedEvent.sceneCardId || '';
    setValue('title', title);
    setValue('description', description);
    setValue('location', location);
    setValue('startDate', startDate);
    setValue('endDate', endDate);
    setValue('startTime', startTime);
    setValue('endTime', endTime);
    setValue('calendarCategoryId', selectedEvent.calendarCategoryId);
    setValue('sceneCardId', sceneCardId);

    const parsedParticipants: Participant[] = participants
      .map((email) => ({ email }))
      .filter(({ email }) => email !== currentUser.email);
    setSelectedParticipants(parsedParticipants);
  }, [selectedEvent]);

  useEffect(() => {
    if (!selectedDate?.start) return;
    setValue('startDate', format(selectedDate.start, 'yyyy-MM-dd'));
    setValue('endDate', format(selectedDate.end, 'yyyy-MM-dd'));
    setValue('startTime', format(selectedDate.start, 'HH:mm'));
    setValue('endTime', format(selectedDate.end, 'HH:mm'));
    setValue('allDay', allDaySelected);
  }, [selectedDate, allDaySelected]);

  const handleSearchForParticipants = useCallback(
    debounce((searchTerm) => {
      const projectMembersWithoutCurrentUser = projectMembers.filter(
        ({ email }) => email !== currentUser.email
      );
      const filteredMembers = filterProjectMembersBySearchTerm(
        projectMembersWithoutCurrentUser,
        searchTerm
      );
      const uniqueFilteredMembers = filteredMembers.filter(
        ({ email }) => !selectedParticipants.some((participant) => participant.email === email)
      ); // remove already selected participants
      setSearchResults(uniqueFilteredMembers);
    }, 200),
    [projectMembers]
  );

  const onSubmit: SubmitHandler<EventFormInput> = (data) => {
    const {
      title,
      description,
      location,
      startTime,
      endTime,
      startDate,
      endDate,
      calendarCategoryId,
      allDay,
      sceneCardId,
    } = data;
    if (submitting) return;
    setSubmitting(true);
    const participants = selectedParticipants.map((participant) => ({ email: participant.email }));

    const when = formatWhen({ startDate, endDate, startTime, endTime, allDay });

    async function updateEvent() {
      try {
        const organizerEmail = selectedEvent?.organizerEmail || currentUser.email; // if there is no organizer, add the current user
        const participantsWithOrganizer = organizerEmail
          ? [...participants, { email: organizerEmail }]
          : participants;
        const result = await api.put(`/calendars/${calendarId}/update_event`, {
          event_input: {
            event_id: selectedEvent.id,
            when,
            title: title?.trim(),
            description: description?.trim(),
            location: location?.trim(),
            participants: participantsWithOrganizer,
            metadata: {
              key1: calendarCategoryId.toString(),
              key2: currentUser.email,
              key3: sceneCardId,
            },
          },
        });
        const newEvent = result?.data;
        if (newEvent) {
          const newEventWithCategory = eventWithCategories([newEvent], calendarCategories)[0];
          setEvents((current) => {
            const eventsWithoutUpdatedEvent = current.filter(
              (event) => event.id !== selectedEvent.id
            );
            return [...eventsWithoutUpdatedEvent, newEventWithCategory];
          });
          setSelectedEvent(newEventWithCategory);
        }
        resetForm();
        showToast('Event updated', 'success');
      } catch (error) {
        showToast('It was not possible to update event', 'danger');
      }
    }

    async function createEvent() {
      try {
        const participantsWithOrganizer = [...participants, { email: currentUser.email }];
        const result = await api.post(`/calendars/${calendarId}/create_event`, {
          event_input: {
            when,
            title: title?.trim(),
            description: description?.trim(),
            location: location?.trim(),
            participants: participantsWithOrganizer,
            metadata: {
              key1: calendarCategoryId.toString(),
              key2: currentUser.email,
              key3: sceneCardId,
            },
          },
        });

        const newEvent = result?.data;
        if (newEvent) {
          const newEventWithCategory = eventWithCategories([newEvent], calendarCategories)[0];
          setEvents((current) => [...current, newEventWithCategory]);
          setSelectedEvent(newEventWithCategory);
        }
        resetForm();
        showToast('Event created', 'success');
      } catch (error) {
        showToast('It was not possible to create event', 'danger');
      }
    }

    isUpdating ? updateEvent() : createEvent();
    setMode('view');
    setSubmitting(false);
  };

  const handleNewParticipant = (participant) => {
    if (!participant) return;
    if (selectedParticipants.some((selected) => selected.email === participant.email)) return;
    setSelectedParticipants((current) => [...current, { email: participant.email }]);
  };

  const handleRemoveParticipant = (participantToRemove) => {
    setSelectedParticipants((current) =>
      current.filter((participant) => participant.email !== participantToRemove.email)
    );
  };

  const handleDeleteEventClicked = () => {
    if (!selectedEvent.id) return;

    async function deleteEvent() {
      try {
        setShowDeleteConfirmationModal(false);
        const result = await api.delete(
          `/calendars/${calendarId}/delete_event/${selectedEvent.id}`
        );
        if (result.status !== 200) {
          throw new Error('Error deleting event');
        }
        showToast('Event deleted', 'success');
        setEvents((current) => current.filter((event) => event.id !== selectedEvent.id));
      } catch (error) {
        setShowDeleteConfirmationModal(false);
        showToast('Error deleting event', 'danger');
      }
    }
    deleteEvent();
    resetForm();
    setIsEventInfoOpen(false);
  };

  const handleDiscardChanges = () => {
    resetForm();
    setShowDiscardConfirmationModal(false);
    if (isUpdating) {
      setMode('view');
    } else {
      setIsEventInfoOpen(false);
    }
  };

  useEffect(() => {
    const dirtyValues = Object.keys(dirtyFields)?.length > 0;
    hasFormChangedRef.current = dirtyValues ? true : false;
  }, [formState]);

  const handleCloseFormButton = () => {
    const hasChanges = hasFormChangedRef?.current;
    if (hasChanges) {
      setShowDiscardConfirmationModal(true);
    } else {
      isUpdating ? setMode('view') : setIsEventInfoOpen(false);
    }
  };

  return (
    <>
      <ConfirmationDialog
        title="Delete event?"
        description="Are you sure you want to delete this event? This event and its details will be permanently deleted."
        onCancelLabel="Cancel"
        onCancelAction={() => setShowDeleteConfirmationModal(false)}
        onConfirmLabel="Confirm"
        onConfirmAction={handleDeleteEventClicked}
        isOpen={showDeleteConfirmationModal}
        onClose={() => setShowDeleteConfirmationModal(false)}
        icon={
          <div className="rounded-full p-3 bg-red-200">
            <ExclamationTriangleIcon className="w-6 h-6 text-red-500 " />
          </div>
        }
      />
      <ConfirmationDialog
        title="Discard changes?"
        description="If you discard changes, you’ll delete any edits you made since you last saved."
        onCancelLabel="Continue editing"
        onCancelAction={() => setShowDiscardConfirmationModal(false)}
        onConfirmLabel="Discard"
        onConfirmAction={handleDiscardChanges}
        isOpen={showDiscardConfirmationModal}
        onClose={() => setShowDiscardConfirmationModal(false)}
        icon={
          <div className="rounded-full p-3 bg-red-200">
            <ExclamationTriangleIcon className="w-6 h-6 text-red-500 " />
          </div>
        }
      />
      <form className="flex flex-col relative w-full h-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="fixed top-18 lg:static w-full lg:w-72 bg-white flex gap-2 border-b p-4 justify-end items-center z-10 lg:z-0">
          {selectedEvent && (
            <TrashIcon
              onClick={() => setShowDeleteConfirmationModal(true)}
              className="text-red-500 w-5 h-5 cursor-pointer hover:text-red-600"
            />
          )}

          <IconButton onClick={handleCloseFormButton}>
            <XMarkIcon className="w-6 h-6 text-gray-500" />
          </IconButton>
        </div>
        <div className="overflow-y-auto lg:overflow-y-visible lg:pt-4 flex flex-col gap-4">
          <div className="border-b px-4 flex flex-col gap-4 pb-4">
            <FormGroupTitle title="What" />
            <div>
              <FormLabel label="Title" />
              <EventFormField
                type="text"
                name="title"
                placeholder={'Example title'}
                errors={errors.title}
                register={register}
                required={true}
                validations={{
                  maxLength: { message: 'Max number of characters (60) exceeded.', value: 60 },
                }}
              />
            </div>
            <div>
              <FormLabel label="Add to sub calendar" />
              <SelectCalendarCategory
                value={watch('calendarCategoryId')}
                onChange={(id) => setValue('calendarCategoryId', id)}
                calendarCategories={filteredCalendarCategories}
              />
            </div>
            <div>
              <FormLabel label="Attach a scene card" />
              <SelectSceneCardCombobox
                projectSceneCards={projectSceneCards}
                handleAttachSceneCard={(sceneCard) => setValue('sceneCardId', sceneCard.uuid)}
                removeSceneCard={() => setValue('sceneCardId', '')}
                currentSceneCardId={watch('sceneCardId')}
              />
            </div>
          </div>
          <div className="border-b px-4 flex flex-col gap-4 pb-4">
            <FormGroupTitle title="When" />
            <div className="flex items-center gap-2">
              <Toggle
                value={watch('allDay')}
                onChange={(value) => {
                  setValue('allDay', value);
                  setAllDaySelected(value);
                }}
              />
              <span className="text-sm leading-5 font-medium text-gray-700">All day</span>
            </div>
            <div>
              <FormLabel label="Start Date" />
              <EventFormField
                type="date"
                name="startDate"
                placeholder={'01/01'}
                errors={errors.startDate}
                register={register}
                control={control}
                watch={watch}
                setValue={setValue}
                required={true}
              />
            </div>
            <div>
              <FormLabel label="End Date" />
              <EventFormField
                type="date"
                name="endDate"
                placeholder={'01/01'}
                errors={errors.endDate}
                register={register}
                control={control}
                watch={watch}
                setValue={setValue}
                required={true}
              />
            </div>
            {!isAllDay && (
              <>
                <div>
                  <FormLabel label="Start Time" />
                  <EventFormField
                    type="time"
                    name="startTime"
                    placeholder={'08:00'}
                    errors={errors.startTime}
                    register={register}
                    control={control}
                    watch={watch}
                    setValue={setValue}
                    required={true}
                  />
                </div>
                <div>
                  <FormLabel label="End Time" />
                  <EventFormField
                    type="time"
                    name="endTime"
                    placeholder={'10:00'}
                    errors={errors.endTime}
                    register={register}
                    control={control}
                    watch={watch}
                    setValue={setValue}
                    required={true}
                  />
                </div>
              </>
            )}
          </div>
          <div className="border-b px-4 flex flex-col gap-4 pb-4">
            <FormGroupTitle title="Where" />
            <div>
              <FormLabel label="Location" />
              <EventFormField
                type="text"
                name="location"
                placeholder={'Street 123'}
                errors={errors.location}
                register={register}
                required={false}
              />
            </div>
          </div>
          <div className="border-b px-4 flex flex-col gap-4 pb-4">
            <FormGroupTitle title="Who" />
            <ParticipantsCombobox
              participants={searchResults}
              handleNewParticipant={handleNewParticipant}
              onSearch={handleSearchForParticipants}
            />
            <div className="flex flex-col gap-1">
              {selectedParticipants.map((participant) => {
                const currentParticipant = projectMembersByEmail[participant.email] || participant;
                return (
                  <ParticipantCardItem
                    key={currentParticipant.id || participant.email}
                    name={currentParticipant.name}
                    role={currentParticipant.role}
                    imageUrl={currentParticipant.imageUrl}
                    email={currentParticipant.email}
                    onClose={() => handleRemoveParticipant(participant)}
                  />
                );
              })}
            </div>
          </div>
          <div className="px-4 flex flex-col gap-4 pb-24 lg:pb-8">
            <FormGroupTitle title="Details" />
            <div>
              <FormLabel label="Notes" />
              <EventFormField
                type="textarea"
                name="description"
                placeholder={'Event Description'}
                errors={errors.description}
                register={register}
                required={false}
              />
            </div>
          </div>
        </div>
        <div className="p-4 border-t flex items-center gap-2 h-20 w-full lg:w-72 fixed bottom-0 z-10 lg:static lg:z-0 bg-white">
          <Button type="button" onClick={handleSubmit(onSubmit)}>
            <span>Save</span>
          </Button>
          <Button type="button" onClick={handleCloseFormButton} variant="secondary">
            <span>Cancel</span>
          </Button>
        </div>
      </form>
    </>
  );
}
