import { Dropdown } from 'tailwindcss-stimulus-components';

export default class extends Dropdown {
  static targets = ['input', 'text', 'menu', 'button'];

  connect() {
    super.connect();
    console.log('WardrobeKitDropdown controller connected!');
    document.addEventListener('click', this.handleOutsideClick.bind(this));
  }

  disconnect() {
    document.removeEventListener('click', this.handleOutsideClick.bind(this));
  }

  select(event) {
    event.preventDefault();
    const value = event.currentTarget.getAttribute('data-value');
    const text = event.currentTarget.textContent;
    this.inputTarget.value = value;
    this.textTarget.textContent = text;

    this.menuTarget.classList.add('hidden');
  }

  handleOutsideClick(event) {
    const { target } = event;
    if (this.element.contains(target) === false) {
      this.menuTarget.classList.add('hidden');
    }
  }

  toggle() {
    this.menuTarget.classList.toggle('hidden');
  }
}
