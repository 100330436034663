import React from 'react';

import Button from './Button';
import Dialog from './Dialog';

interface ConfirmationDialogProps {
  title: string;
  description: string;
  onConfirmLabel: string;
  onCancelLabel: string;
  onConfirmAction: () => void;
  confirmVariant?: 'danger' | 'primary';
  onCancelAction: () => void;
  isOpen: boolean;
  onClose: () => void;
  icon: React.ReactNode;
}

export default function ConfirmationDialog({
  title,
  description,
  onConfirmLabel,
  onCancelLabel,
  onConfirmAction,
  confirmVariant,
  onCancelAction,
  isOpen,
  onClose,
  icon,
}: ConfirmationDialogProps) {
  return (
    <Dialog className="max-w-md" open={isOpen} onClose={onClose}>
      <div className="flex gap-4 p-6">
        {icon && <div>{icon}</div>}
        <div>
          <div className="flex flex-col gap-2">
            <div className="text-lg leading-6 font-medium text-gray-900">{title}</div>
            <div className="text-sm leading-5 font-normal text-gray-500">{description}</div>
          </div>
          <div className="flex gap-2 mt-4">
            <Button variant="secondary" onClick={onCancelAction}>
              {onCancelLabel}
            </Button>
            <Button variant={confirmVariant || 'danger'} onClick={onConfirmAction}>
              {onConfirmLabel}
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
