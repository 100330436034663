import React from 'react';

const SegmentedControl = ({ buttons, selectedControl, onButtonClick, className = '' }) => {
  return (
    <div className={`flex justify-center segment-control px-2 lg:px-52 ${className}`}>
      {buttons.map((button, index) => (
        <button
          key={button.name}
          className={`flex-1 text-center py-2 px-4 focus:outline-none whitespace-nowrap ${
            selectedControl === button.name ? 'bg-blue-500 text-white' : 'bg-white text-gray-700'
          } ${index === 0 ? 'rounded-l-lg' : ''} ${
            index === buttons.length - 1 ? 'rounded-r-lg' : ''
          }`}
          onClick={() => onButtonClick(button.name)}
        >
          {button.name}
        </button>
      ))}
    </div>
  );
};

export default SegmentedControl;
