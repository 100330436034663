import { Controller } from 'stimulus';

export default class extends Controller {
  static get values() {
    return { state: String, dropdownOpen: Boolean };
  }

  static get targets() {
    return [
      'countryInput',
      'stateInput',
      'countryButton',
      'stateButton',
      'dropdown',
      'stateDropdown',
      'countryName',
      'stateName',
      'stateOptions',
    ];
  }

  connect() {
    this.fillStatesField();
    this.dropdownOpenValue = false;
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    document.addEventListener('click', this.handleOutsideClick);
  }

  disconnect() {
    document.removeEventListener('click', this.handleOutsideClick);
  }

  setCountry(event) {
    event.preventDefault();

    const country = event.target.dataset.country;
    const countryName = event.target.dataset.countryName;

    this.countryInputTarget.value = country;
    this.countryNameTarget.textContent = countryName;

    this.stateInputTarget.value = '';
    this.stateNameTarget.textContent = '';

    this.fillStatesField();
    this.dropdownOpenValue = false;
    this.dropdownTarget.classList.add('hidden');
  }

  setState(event) {
    event.preventDefault();

    const state = event.target.dataset.state;
    const stateName = event.target.dataset.stateName;

    this.stateInputTarget.value = state;
    this.stateNameTarget.textContent = stateName;
    this.dropdownOpenValue = false;
    this.stateDropdownTarget.classList.add('hidden');
  }

  toggleDropdown(event) {
    event.stopPropagation();
    const button =
      event.target.closest('[data-country-state-target="stateButton"]') ||
      event.target.closest('button');

    if (button) {
      const isCountryDropdown = button.dataset.countryStateTarget === 'countryButton';
      const isStateDropdown = button.dataset.countryStateTarget === 'stateButton';

      if (isCountryDropdown) {
        this.dropdownOpenValue = !this.dropdownOpenValue;
        this.dropdownTarget.classList.toggle('hidden', !this.dropdownOpenValue);
      } else if (isStateDropdown) {
        this.dropdownOpenValue = !this.dropdownOpenValue;
        this.stateDropdownTarget.classList.toggle('hidden', !this.dropdownOpenValue);
      }
    }
  }

  closeDropdown() {
    this.dropdownOpenValue = false;
    this.dropdownTarget.classList.add('hidden');
    this.stateDropdownTarget.classList.add('hidden');
  }

  handleOutsideClick(event) {
    if (!this.element.contains(event.target)) {
      this.dropdownOpenValue = false;
      this.dropdownTarget.classList.add('hidden');
      this.stateDropdownTarget.classList.add('hidden');
    }
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  async fillStatesField() {
    const stateLabel = document.querySelector("label[for='user_state']");
    const stateButton = this.stateButtonTarget;

    stateButton.disabled = !this.countryInputTarget.value;

    if (this.countryInputTarget.value === 'GB') {
      stateLabel.innerHTML = 'County*';
    } else if (this.countryInputTarget.value === 'CA') {
      stateLabel.innerHTML = 'Province*';
    } else {
      stateLabel.innerHTML = 'State*';
    }

    if (!this.countryInputTarget.value) return;

    this.stateOptionsTarget.innerHTML = '';

    try {
      const response = await fetch(`/states?country=${this.countryInputTarget.value}`, {
        credentials: 'same-origin',
      });
      const data = await response.json();

      this.stateNameTarget.textContent = data[this.stateValue];

      Object.keys(data).forEach((key) => {
        const link = document.createElement('a');
        link.href = '#';
        link.classList.add(
          'text-gray-700',
          'block',
          'px-4',
          'py-2',
          'text-sm',
          'hover:bg-gray-100'
        );
        link.dataset.action = 'click->country-state#setState';
        link.dataset.state = key;
        link.dataset.stateName = data[key];
        link.textContent = data[key];
        this.stateOptionsTarget.appendChild(link);
      });
    } catch (error) {
      console.error('Error:', error);
    }
  }
}
