import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { addDays, addWeeks, format, subSeconds } from 'date-fns';
import keyBy from 'lodash/keyBy';
import { useMedia } from 'react-use';
import { twMerge } from 'tailwind-merge';

import {
  CalendarApi,
  DateSelectArg,
  DatesSetArg,
  EventChangeArg,
  EventClickArg,
  EventContentArg,
} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

import { api } from '../../lib/api';
import ConfirmationDialog from '../shared/ConfirmationDialog';
import Dialog from '../shared/Dialog';
import { showToast } from '../shared/Toast';
import { CalendarToolbar } from './CalendarToolbar';
import EventContent from './EventContent';
import EventDetails from './EventDetails';
import { EventForm } from './EventForm';
import {
  eventWithCategories,
  filterByCalendarCategories,
  formatWhenDragUpdate,
} from './eventUtils';
import { MobileSettingsSideBar, SettingsSideBar } from './SettingsSideBar';

export interface CalendarEvent {
  id: string;
  title: string;
  description: string;
  location: string;
  calendarCategoryId: number;
  start: Date;
  end: Date;
  participants: string[];
  color?: string;
  allDay: boolean;
  organizerEmail: string;
  sceneCardId: string;
}

export type ProjectRole =
  | 'stunt_office_coordinator'
  | 'coordinator_admin'
  | 'coordinator'
  | 'super_coordinator'
  | 'performer'
  | 'hair_and_makeup'
  | 'wardrobe'
  | 'production'
  | 'production_manager'
  | 'assistant_director';

export interface CalendarProps {
  projectId: string;
  calendarId: string;
  initialEvents: CalendarEvent[];
  calendarCategories: CalendarCategory[];
  currentUser: CurrentUser;
  isProjectAdmin: boolean;
  projectRole: ProjectRole;
  urlSelectedEventId: string;
  urlSelectedEventDate: string;
}

export interface CalendarCategory {
  id: number;
  calendar_category_group: string;
  color: string;
  name: string;
}

export interface CurrentUser {
  id: number;
  email: string;
}

export interface ProjectMember {
  id: number;
  name: string;
  email: string;
  role: string;
  imageUrl: string;
}

export interface SceneCard {
  uuid: string;
  scene_name: string;
  scene: string;
}

export default function Calendar({
  calendarId,
  projectId,
  initialEvents,
  calendarCategories,
  currentUser,
  isProjectAdmin,
  projectRole,
  urlSelectedEventId,
  urlSelectedEventDate,
}: CalendarProps) {
  const calendarWrapperRef = useRef(null);
  const defaultSelectedCalendarCategories = calendarCategories.map((category) => category.id); // all calendar categories
  const [events, setEvents] = useState(eventWithCategories(initialEvents, calendarCategories));
  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent>(null);
  const [selectedCalendarCategories, setSelectedCalendarCategories] = useState(
    defaultSelectedCalendarCategories
  );
  const [selectedDate, setSelectedDate] = useState({
    start: null,
    end: null,
  });
  const [allDaySelected, setAllDaySelected] = useState(false);
  const [mode, setMode] = useState('view');
  const [projectMembers, setProjectMembers] = useState<ProjectMember[]>([]);
  const [isSettingsOpen, setIsSettingsOpen] = useState(true);
  const [isMobileSettingsOpen, setIsMobileSettingsOpen] = useState(false);
  const [isEventInfoOpen, setIsEventInfoOpen] = useState(false);
  const [showConfirmationModal, setShowConfirmationDialog] = useState(false);
  const [confirmDialogActions, setConfirmDialogActions] = useState(null);
  const calendarRef = useRef(null);
  const [projectSceneCards, setProjectSceneCards] = useState<SceneCard[]>([]);

  const projectMembersByEmail = useMemo(() => keyBy(projectMembers, 'email'), [projectMembers]);
  const eventsById = useMemo(() => keyBy(events, 'id'), [events]);
  const calendarCategoriesById = useMemo(
    () => keyBy(calendarCategories, 'id'),
    [calendarCategories]
  );
  const allowCreateEvents = useMemo(() => {
    const allowedRoles: ProjectRole[] = [
      'production_manager',
      'coordinator',
      'stunt_office_coordinator',
      'wardrobe',
      'hair_and_makeup',
    ];
    return isProjectAdmin || allowedRoles.includes(projectRole);
  }, [isProjectAdmin, projectRole]);

  const isMobileScreen = useMedia('(max-width: 1024px)', false);

  const initialView = 'dayGridMonth';

  const getCurrentView = useCallback(() => {
    if (!calendarRef?.current) return null;
    const calendarApi: CalendarApi = calendarRef.current.getApi();
    return calendarApi.view.type;
  }, []);

  const triggerCalendarResize = useCallback(() => {
    if (!calendarRef?.current) return null;
    const calendarApi: CalendarApi = calendarRef.current.getApi();
    calendarApi.updateSize();
  }, []);

  const unselectHighlight = useCallback(() => {
    if (!calendarRef?.current) return null;
    const calendarApi: CalendarApi = calendarRef.current.getApi();
    calendarApi.unselect();
  }, []);

  const goToToday = useCallback(() => {
    if (!calendarRef?.current) return null;
    const calendarApi: CalendarApi = calendarRef.current.getApi();
    calendarApi.today();
  }, []);

  const goToNext = useCallback(() => {
    if (!calendarRef?.current) return null;
    const calendarApi: CalendarApi = calendarRef.current.getApi();
    calendarApi.next();
  }, []);

  const goToPrevious = useCallback(() => {
    if (!calendarRef?.current) return null;
    const calendarApi: CalendarApi = calendarRef.current.getApi();
    calendarApi.prev();
  }, []);

  const goToDate = useCallback((date) => {
    if (!calendarRef?.current) return null;
    const calendarApi: CalendarApi = calendarRef.current.getApi();
    return calendarApi.gotoDate(date);
  }, []);

  const changeCalendarView = useCallback(
    (view: string) => {
      if (!calendarRef?.current || !view) return null;
      const calendarApi: CalendarApi = calendarRef.current.getApi();
      calendarApi.changeView(view);
      if (selectedDate?.start) {
        return calendarApi.gotoDate(selectedDate.start);
      }
      if (selectedEvent) {
        return calendarApi.gotoDate(selectedEvent.start);
      }
    },
    [selectedDate, selectedEvent]
  );

  const getTitle = useCallback(() => {
    if (!calendarRef?.current) return null;
    const calendarApi: CalendarApi = calendarRef.current.getApi();
    const activeStartDate = calendarApi.view.activeStart;
    const isDayView = calendarApi.view.type === 'timeGridDay';
    const isMonthView = calendarApi.view.type === 'dayGridMonth';

    if (isDayView) {
      return format(activeStartDate, 'MMM do yyyy');
    }

    if (isMonthView) {
      return isMobileScreen
        ? format(addWeeks(activeStartDate, 1), 'MMM yyyy')
        : format(addWeeks(activeStartDate, 1), 'MMMM yyyy');
    }

    return isMobileScreen
      ? format(activeStartDate, 'MMM yyyy')
      : format(activeStartDate, 'MMMM yyyy');
  }, [isMobileScreen]);

  const handleDateSelect = (selectInfo: DateSelectArg) => {
    const { start, end, view, allDay } = selectInfo;
    const monthlyClick = view.type === 'dayGridMonth';

    if (mode === 'view') {
      setSelectedEvent(null);
    }

    // end date is exclusive in all day events, check: https://fullcalendar.io/docs/event-object
    if (monthlyClick || allDay) {
      setSelectedDate({
        start,
        end: subSeconds(end, 1),
      });
      setAllDaySelected(true);
      return setIsEventInfoOpen(true);
    }

    setAllDaySelected(false);
    setSelectedDate({
      start: start,
      end: end,
    });

    setIsEventInfoOpen(true);
  };

  const handleEventClick = (clickInfo: EventClickArg) => {
    const eventId = clickInfo.event.id;
    const event = eventsById[eventId];
    unselectHighlight();
    setMode('view');
    setSelectedEvent(event);
    setSelectedDate(null);
    setIsEventInfoOpen(true);
  };

  const renderEventContent = (eventInfo: EventContentArg) => {
    return <EventContent eventInfo={eventInfo} />;
  };

  // TODO: add debounce
  const handleDateChange = (dateInfo: DatesSetArg) => {
    async function fetchEvents() {
      try {
        const response = await api.get(
          `/calendars/${calendarId}/fetch_events?starts_after=${dateInfo.startStr}}`
        );
        const eventList = response?.data;
        if (eventList) {
          setEvents(eventWithCategories(eventList, calendarCategories));
        }
      } catch (error) {
        showToast('Error fetching events', 'danger');
      }
    }
    fetchEvents();
  };

  const handleEventDragChange = (changeInfo: EventChangeArg) => {
    async function updateEvent() {
      try {
        const { start, end, allDay } = changeInfo.event;
        const when = formatWhenDragUpdate({ start, end, allDay });

        const result = await api.put(`/calendars/${calendarId}/update_event`, {
          event_input: {
            event_id: changeInfo.event.id,
            when,
          },
        });
        const newEvent = result?.data;
        if (newEvent) {
          const newEventWithCategory = eventWithCategories([newEvent], calendarCategories)[0];
          setEvents((current) => {
            const eventsWithoutUpdatedEvent = current.filter(
              (event) => event.id !== changeInfo.event.id
            );
            return [...eventsWithoutUpdatedEvent, newEventWithCategory];
          });
          setSelectedEvent(newEventWithCategory);
          setMode('view');
        }
      } catch (error) {
        showToast('It was not possible to update event', 'danger');
      }
    }
    setShowConfirmationDialog(true);
    setConfirmDialogActions({
      onConfirm: () => {
        updateEvent();
        setShowConfirmationDialog(false);
      },
      onCancel: () => {
        changeInfo.revert();
        setShowConfirmationDialog(false);
      },
    });
  };

  useEffect(() => {
    if (selectedEvent) return;
    if (urlSelectedEventId && urlSelectedEventDate) {
      const event = eventsById[urlSelectedEventId];
      if (event) {
        setSelectedEvent(event);
        setMode('view');
        setIsEventInfoOpen(true);
        const { start } = event;
        goToDate(start);
      }
    }
  }, [urlSelectedEventDate, urlSelectedEventId, eventsById, goToDate]);

  useEffect(() => {
    const view = getCurrentView();
    if (selectedDate && view === 'dayGridMonth') {
      const { start, end } = selectedDate;
      start?.setHours(8, 0, 0, 0);
      end?.setHours(10, 0, 0, 0);

      if (!allDaySelected) {
        setSelectedDate({
          start: start,
          end: end,
        });
      }
    }
  }, [allDaySelected]);

  const filteredEvents = useMemo(() => {
    return filterByCalendarCategories(events, selectedCalendarCategories);
  }, [events, selectedCalendarCategories]);

  useEffect(() => {
    async function fetchProjectMembers() {
      try {
        const response = await api.get(`/projects/${projectId}/participants`);
        const { users } = response.data;

        setProjectMembers(users);
      } catch (error) {
        showToast('Error while fetching participants.', 'danger');
      }
    }
    fetchProjectMembers();
  }, [projectId]);

  // redraw calendar when sidebar is opened/closed (animation)
  useEffect(() => {
    const observer = new ResizeObserver(triggerCalendarResize);

    if (calendarWrapperRef?.current) {
      observer.observe(calendarWrapperRef.current);
    }

    return () => {
      if (calendarWrapperRef?.current) {
        observer.unobserve(calendarWrapperRef.current);
      }
    };
  }, []);

  const onSettingsButtonClick = () => {
    if (isMobileScreen) {
      setIsMobileSettingsOpen(true);
    } else {
      setIsSettingsOpen(!isSettingsOpen);
    }
  };

  function handleAllDayEvents(event: CalendarEvent) {
    // fix multi day all day events
    if (event.allDay && event.end) {
      return {
        ...event,
        end: format(addDays(new Date(`${event.end}T00:00:00`), 1), 'yyyy-MM-dd'),
      };
    }
    return event;
  }

  useEffect(() => {
    async function fetchProjectSceneCards() {
      try {
        const response = await api.get(`/projects/${projectId}/scene_cards`);
        if (response.status !== 200) throw new Error('Error while fetching scene cards.');
        setProjectSceneCards(response.data.scene_cards);
      } catch (error) {
        showToast('Error while fetching scene cards.', 'danger');
      }
    }
    fetchProjectSceneCards();
  }, [projectId]);

  const sceneCardsById = useMemo(() => keyBy(projectSceneCards, 'uuid'), [projectSceneCards]);

  const handleSceneCardRedirect = useCallback(
    async (sceneCardId) => {
      const projectFolderId = sceneCardsById[sceneCardId]?.project_folder?.uuid;
      const { Turbo } = await import('@hotwired/turbo-rails');
      Turbo.visit(
        `/projects/${projectId}/project_folders/${projectFolderId}/scene_cards/${sceneCardId}`
      );
    },
    [calendarId, selectedEvent, sceneCardsById]
  );

  return (
    <div className="flex gap-2 border-t w-full" style={{ height: 'calc(100vh - 68px)' }}>
      <ConfirmationDialog
        title="Are you sure?"
        description="Changing this event will affect all participants."
        onCancelLabel="Cancel"
        onCancelAction={confirmDialogActions?.onCancel}
        onConfirmLabel="Confirm"
        onConfirmAction={confirmDialogActions?.onConfirm}
        isOpen={showConfirmationModal}
        onClose={confirmDialogActions?.onCancel}
        icon={
          <div className="rounded-full p-3 bg-red-200">
            <ExclamationTriangleIcon className="w-6 h-6 text-red-500 " />
          </div>
        }
      />
      <SettingsSideBar
        className={twMerge(
          'hidden lg:flex flex-col bg-white shadow-sm w-72 overflow-y-auto flex-shrink-0',
          'transition-all duration-300 ease-in-out',
          isSettingsOpen ? 'w-72 opacity-100' : 'w-0 opacity-0'
        )}
        calendarId={calendarId}
        calendarCategories={calendarCategories}
        selectedCalendarCategories={selectedCalendarCategories}
        setSelectedCalendarCategories={setSelectedCalendarCategories}
        isProjectAdmin={isProjectAdmin}
      />
      <div ref={calendarWrapperRef} className="p-2 w-full">
        <CalendarToolbar
          goToNext={goToNext}
          goToPrevious={goToPrevious}
          goToToday={goToToday}
          onSettingsButtonClick={onSettingsButtonClick}
          getTitle={getTitle}
          changeCalendarView={changeCalendarView}
          currentView={getCurrentView()}
        />
        <div className={twMerge('w-full')} style={{ height: 'calc(100vh - 136px)' }}>
          <FullCalendar
            eventClassNames={(eventInfo) => {
              const isSelected = selectedEvent?.id === eventInfo.event.id;
              return isSelected ? 'selected-event' : '';
            }}
            ref={calendarRef}
            slotLabelClassNames={['text-xs text-gray-500']}
            viewClassNames={['w-full text-sm text-gray-500 bg-white']}
            allDayText="All Day"
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            titleFormat={{ year: 'numeric', month: 'long' }}
            nowIndicatorClassNames={['w-full']}
            headerToolbar={false}
            expandRows={true}
            slotDuration={'00:30:00'}
            eventMinHeight={30}
            height="100%"
            initialView={initialView}
            editable={isProjectAdmin || projectRole === 'production_manager'}
            views={{
              week: {
                dayHeaderFormat: { day: '2-digit', weekday: 'short' },
              },
              day: {
                dayHeaderFormat: { day: '2-digit', weekday: 'short' },
              },
            }}
            longPressDelay={500}
            selectMirror={false}
            selectable={allowCreateEvents}
            scrollTime={'06:00:00'}
            dayMaxEvents={true}
            nowIndicator={true}
            weekends={true}
            unselectAuto={false}
            events={filteredEvents?.filter(Boolean)}
            eventChange={handleEventDragChange}
            eventContent={renderEventContent}
            eventClick={handleEventClick}
            datesSet={handleDateChange}
            select={handleDateSelect}
            windowResizeDelay={300}
            eventDataTransform={handleAllDayEvents}
          />
        </div>
      </div>

      <div
        className={twMerge(
          'hidden lg:flex shadow-sm bg-white w-72 flex-shrink-0 overflow-y-auto',
          'transition-all duration-300 ease-in-out',
          isEventInfoOpen ? 'w-72 opacity-100' : 'w-0 opacity-0'
        )}
      >
        {selectedEvent && mode === 'view' ? (
          <EventDetails
            selectedEvent={selectedEvent}
            calendarCategoriesById={calendarCategoriesById}
            setMode={setMode}
            projectMembersByEmail={projectMembersByEmail}
            setIsEventInfoOpen={setIsEventInfoOpen}
            isProjectAdmin={isProjectAdmin}
            projectRole={projectRole}
            sceneCard={sceneCardsById[selectedEvent?.sceneCardId]}
            handleSceneCardRedirect={handleSceneCardRedirect}
          />
        ) : (
          <EventForm
            projectMembers={projectMembers}
            projectMembersByEmail={projectMembersByEmail}
            projectSceneCards={projectSceneCards}
            currentUser={currentUser}
            selectedEvent={selectedEvent}
            setSelectedEvent={setSelectedEvent}
            setEvents={setEvents}
            selectedDate={selectedDate}
            calendarId={calendarId}
            calendarCategories={calendarCategories}
            setMode={setMode}
            setIsEventInfoOpen={setIsEventInfoOpen}
            setAllDaySelected={setAllDaySelected}
            allDaySelected={allDaySelected}
            projectRole={projectRole}
          />
        )}
      </div>
      {isMobileScreen && (
        <>
          <Dialog open={isMobileSettingsOpen} onClose={() => setIsMobileSettingsOpen(false)}>
            <MobileSettingsSideBar
              currentView={getCurrentView()}
              calendarId={calendarId}
              calendarCategories={calendarCategories}
              selectedCalendarCategories={selectedCalendarCategories}
              setSelectedCalendarCategories={setSelectedCalendarCategories}
              changeCalendarView={changeCalendarView}
              initialView={initialView}
              setIsSettingsOpen={setIsMobileSettingsOpen}
              isProjectAdmin={isProjectAdmin}
            />
          </Dialog>
          <Dialog open={isEventInfoOpen} onClose={() => setIsEventInfoOpen(false)}>
            {selectedEvent && mode === 'view' ? (
              <div style={{ height: 'calc(100vh - 64px)', maxHeight: '840px' }}>
                <EventDetails
                  sceneCard={sceneCardsById[selectedEvent?.sceneCardId]}
                  selectedEvent={selectedEvent}
                  calendarCategoriesById={calendarCategoriesById}
                  setMode={setMode}
                  projectMembersByEmail={projectMembersByEmail}
                  setIsEventInfoOpen={setIsEventInfoOpen}
                  isProjectAdmin={isProjectAdmin}
                  projectRole={projectRole}
                  handleSceneCardRedirect={handleSceneCardRedirect}
                />
              </div>
            ) : (
              <div style={{ height: 'calc(100vh - 64px)', maxHeight: '840px' }}>
                <EventForm
                  projectMembers={projectMembers}
                  projectMembersByEmail={projectMembersByEmail}
                  projectSceneCards={projectSceneCards}
                  currentUser={currentUser}
                  selectedEvent={selectedEvent}
                  setSelectedEvent={setSelectedEvent}
                  setEvents={setEvents}
                  selectedDate={selectedDate}
                  calendarId={calendarId}
                  calendarCategories={calendarCategories}
                  setMode={setMode}
                  setIsEventInfoOpen={setIsEventInfoOpen}
                  setAllDaySelected={setAllDaySelected}
                  allDaySelected={allDaySelected}
                  projectRole={projectRole}
                />
              </div>
            )}
          </Dialog>
        </>
      )}
    </div>
  );
}
