import {
  addHours,
  format,
  isSameDay,
  subSeconds,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import {
  CalendarCategory,
  CalendarEvent,
} from './Calendar';

function getCalendarCategoryColor(event: CalendarEvent, calendarCategories: CalendarCategory[]) {
  return (
    calendarCategories.find((category) => +category.id === +event.calendarCategoryId)?.color ||
    'salmon'
  );
}

export function eventWithCategories(
  events: CalendarEvent[],
  calendarCategories: CalendarCategory[]
): CalendarEvent[] {
  if (!events?.length) return [];
  return events.map((event) => {
    return {
      ...event,
      color: getCalendarCategoryColor(event, calendarCategories),
    };
  });
}

export function filterByCalendarCategories(parsedEventList, selectedCalendarCategories) {
  return parsedEventList.filter((event) =>
    selectedCalendarCategories.includes(event.calendarCategoryId)
  );
}

export function formatIsoToDateInUTC(isoString) {
  const utcDate = utcToZonedTime(isoString, 'Etc/UTC');
  return utcDate;
}

export function formatDates({ start, end, allDay }) {
  if (allDay) {
    // allDay dates are in iso format yyyy-mm-dd
    return {
      parsedStart: start ? formatIsoToDateInUTC(start) : null,
      parsedEnd: end ? formatIsoToDateInUTC(end) : formatIsoToDateInUTC(start), // start date and end date are the same for single day allDay events
    };
  }
  return {
    parsedStart: start ? new Date(start) : null,
    parsedEnd: end ? new Date(end) : null,
  };
}

export function formatWhen({ startDate, endDate, startTime, endTime, allDay }) {
  if (startDate instanceof Date) {
    startDate = format(startDate, 'yyyy-MM-dd');
  }

  if (endDate instanceof Date) {
    endDate = format(endDate, 'yyyy-MM-dd');
  }

  if (startTime instanceof Date) {
    startTime = format(startTime, 'HH:mm');
  }

  if (endTime instanceof Date) {
    endTime = format(endTime, 'HH:mm');
  }

  if (!allDay) {
    return {
      start_time: new Date(`${startDate} ${startTime}`).getTime() / 1000,
      end_time: new Date(`${endDate} ${endTime}`).getTime() / 1000,
    };
  }

  const start = new Date(`${startDate} ${startTime}`);
  const end = new Date(`${endDate} ${endTime}`);
  const singleDate = isSameDay(start, end);

  if (!singleDate) {
    return {
      start_date: format(new Date(`${startDate} ${startTime}`), 'yyyy-MM-dd'),
      end_date: format(new Date(`${endDate} ${endTime}`), 'yyyy-MM-dd'),
    };
  }

  return {
    date: format(new Date(`${startDate} ${startTime}`), 'yyyy-MM-dd'),
  };
}

export function formatWhenDragUpdate({ start, end, allDay }) {
  if (!allDay) {
    return {
      start_time: start.getTime() / 1000,
      end_time: end ? end.getTime() / 1000 : addHours(start, 1).getTime() / 1000
    };
  }

  // it's a single day all day event
  if (!end) {
    return { date: format(start, 'yyyy-MM-dd') };
  }

  const fixedEnd = subSeconds(end, 1);

  return { start_date: format(start, 'yyyy-MM-dd'), end_date: format(fixedEnd, 'yyyy-MM-dd') };
}