import React, { useCallback, useState } from 'react';
import api from '../../lib/api';

import ActionItem, { SceneCardActionProps } from './ActionItem';
import DocumentIcon from '../../assets/icons/DocumentIcon';
import FullScreenSquareIcon from '../../assets/icons/FullScreenSquareIcon';
import HighlightedItem from './HighlightedItem';
import NotebookIcon from '../../assets/icons/NotebookIcon';
import PencilIcon from '../../assets/icons/PencilIcon';
import ReviewFeatureCardHeader from './ReviewFeatureCardHeader';
import ReviewFeatureCardItem from './ReviewFeatureCardItem';
import TagItem from './TagItem';
import UserHandsIcon from '../../assets/icons/UserHandsIcon';
import { showToast } from '../shared/Toast';

interface ReviewFeatureCardProps {
  projectId: string;
  projectFolderId: string;
  sceneCard?: {
    uuid: string;
    scene?: string;
    scene_name?: string;
    summary?: {
      body?: string;
    };
    characters: string[] | [];
    highlights: string[] | [];
    scene_card_actions?: SceneCardActionProps[] | [];
    description?: string;
  };
  onAccept: (uuid: string) => void;
  onArchive: (uuid: string) => void;
}

export default function ReviewFeatureCard({
  projectId,
  projectFolderId,
  sceneCard,
  onAccept,
  onArchive,
}: ReviewFeatureCardProps) {
  const [loading, setLoading] = useState(false);

  function formatMultilineText(text) {
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  }

  const handleOnAccept = useCallback(async () => {
    try {
      if (loading) return;
      setLoading(true);

      const response = await api.put(
        `/projects/${projectId}/project_folders/${projectFolderId}/scene_cards/${sceneCard.uuid}`,
        {
          scene_card: {
            pending_review: false,
          },
        },
        {
          headers: {
            Accept: 'application/json',
          },
        }
      );

      if (response.data.status === 'success') {
        onAccept(sceneCard.uuid);
      } else {
        showToast('There was an error accepting this scene card. Please try again.', 'danger');
      }
      onAccept(sceneCard.uuid);
      setLoading(false);
    } catch (error) {
      showToast('There was an error accepting this scene card. Please try again.', 'danger');
      setLoading(false);
    }
  }, [projectId, projectFolderId, sceneCard.uuid, onAccept]);

  const handleOnArchive = useCallback(async () => {
    try {
      if (loading) return;
      setLoading(true);

      const response = await api.delete(
        `/projects/${projectId}/project_folders/${projectFolderId}/scene_cards/${sceneCard.uuid}/archive`
      );

      if (response.data.status === 'success') {
        onArchive(sceneCard.uuid);
      } else {
        showToast('There was an error archiving this scene card. Please try again.', 'danger');
      }
      setLoading(false);
    } catch (error) {
      showToast('There was an error archiving this scene card. Please try again.', 'danger');
      setLoading(false);
    }
  }, [projectId, projectFolderId, sceneCard.uuid, onArchive]);

  return (
    <div className="flex flex-col bg-white shadow rounded-lg p-4 gap-4">
      <ReviewFeatureCardHeader
        sceneNumber={sceneCard.scene}
        sceneTitle={sceneCard.scene_name}
        onAccept={handleOnAccept}
        onArchive={handleOnArchive}
      />
      {sceneCard?.summary?.body && (
        <ReviewFeatureCardItem
          icon={<DocumentIcon />}
          title="Scene summary"
          description={sceneCard.summary.body}
        />
      )}
      {sceneCard?.characters?.length > 0 && (
        <ReviewFeatureCardItem
          icon={<UserHandsIcon />}
          title="Action characters detected"
          description="Our AI noticed these characters were related to the action."
        >
          <div className="flex flex-wrap gap-2 mt-4">
            {sceneCard?.characters.map((character) => (
              <TagItem text={character} key={character} />
            ))}
          </div>
        </ReviewFeatureCardItem>
      )}
      {sceneCard?.highlights?.length > 0 && (
        <ReviewFeatureCardItem
          icon={<PencilIcon />}
          title="Highlights detected"
          description="Our AI noticed some highlights on the script for this scene. This can indicate that you may have noticed something of interest prior to uploading the script."
        >
          <div className="flex flex-col gap-2">
            {[...new Set(sceneCard?.highlights)].map((highlight) => (
              <HighlightedItem text={highlight} key={highlight} />
            ))}
          </div>
        </ReviewFeatureCardItem>
      )}
      {sceneCard?.scene_card_actions?.length > 0 && (
        <ReviewFeatureCardItem
          icon={<FullScreenSquareIcon />}
          title="Action detected"
          description="Our AI noticed some form of action in the script for this scene."
        >
          <div className="scroll-indicator flex flex-col gap-2 overflow-auto max-h-96">
            {sceneCard?.scene_card_actions.map((action) => {
              return <ActionItem text={action?.text} feature={action?.feature} key={action?.id} />;
            })}
          </div>
        </ReviewFeatureCardItem>
      )}
      {sceneCard?.description && (
        <ReviewFeatureCardItem
          icon={<NotebookIcon />}
          title="Scene text"
          description={formatMultilineText(sceneCard.description)}
          descriptionClassName="text-center"
        />
      )}
    </div>
  );
}
