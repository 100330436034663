import Rails from '@rails/ujs';

export default function waitJobComplete(id) {
  return new Promise((resolve, reject) => {
    const interval = setInterval(
      () =>
        Rails.ajax({
          url: '/uppy/check_conversion_status',
          type: 'GET',
          data: new URLSearchParams({ job_id: id }).toString(),
          success: ({ Job }) => {
            if (Job.status === 'COMPLETE' || Job.status === 'ERROR') {
              clearInterval(interval);
            } else {
              return;
            }
            if (Job.status === 'COMPLETE') resolve(Job);
            if (Job.status === 'ERROR') reject(Job.error_message);
          },
          error: (error) => {
            clearInterval(interval);
            reject(error);
          },
        }),
      60 * 1000
    );
  });
}
