export const countries = [{ id: 'US', name: '🇺🇸 USA' }, { id: 'CA', name: '🇨🇦 Canada' }, { id: 'GB', name: '🇬🇧 Great Britain' }];

export const ethnicities = [
    { id: 'african_american_black', name: 'African American / Black' },
    { id: 'asian', name: 'Asian' },
    { id: 'caribbean', name: 'Caribbean' },
    { id: 'caucasian_white', name: 'Caucasian / White' },
    { id: 'easter_european', name: 'Eastern European' },
    { id: 'indian_south_asian', name: 'Indian / South Asian' },
    { id: 'latin_hispanic', name: 'Latin / Hispanic' },
    { id: 'mediterranean', name: 'Mediterranean' },
    { id: 'middle_eastern', name: 'Middle Eastern' },
    { id: 'native_american', name: 'Native American' },
    { id: 'pacific_islander_polynesian', name: 'Pacific Islander / Polynesian' },

]

export const genders = [{ id: 'male', name: 'Male' }, { id: 'female', name: 'Female' }];