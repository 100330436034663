import React from 'react';

import ActionItem from './ActionItem';
import ReviewFeatureCardItem from './ReviewFeatureCardItem';

export default function SceneActionsOverview({ sceneCard }) {
  return (
    <div className="bg-gray-50">
      {sceneCard?.scene_card_actions?.length > 0 && (
        <ReviewFeatureCardItem
          description="Our AI noticed some form of action in the script for this scene."
          borderColor="border-gray-300"
        >
          <div className="flex flex-col gap-2 overflow-auto max-h-60">
            {sceneCard?.scene_card_actions.map((action) => {
              return (
                <ActionItem
                  text={action?.text}
                  feature={action?.feature}
                  bgColor="bg-white"
                  key={action?.id}
                />
              );
            })}
          </div>
        </ReviewFeatureCardItem>
      )}
    </div>
  );
}
