import React from 'react';

export default function HighlightedItem({ text }) {
  return (
    <div>
      <div className="p-1 inline-block bg-yellow-50 rounded-lg">
        <span className="text-gray-900 text-sm leading-5 font-normal">{text}</span>
      </div>
    </div>
  );
}
