import React from 'react';

export interface AddButtonProps {
  title: string;
  onClick?: () => void;
}

export default function AddButton({ title, onClick }) {
  return (
    <div className="float-right flex cursor-pointer" onClick={onClick}>
      <a className="lg:inline-flex hidden text-base leading-6 font-medium items-center mr-2 text-blue-500">
        {title}
      </a>
      <a className="inline-flex items-center p-1.5 border border-transparent rounded-full shadow-sm text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
        <svg
          className="h-6 w-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
          ></path>
        </svg>
      </a>
    </div>
  );
}
