import React from 'react';
import MagicStick from '../../assets/icons/MagicStick';
import NewFeatureAlert from './NewFeatureAlert';

export default function SceneCardAIBetaAlert() {
  return (
    <NewFeatureAlert icon={<MagicStick />} title="Use AI to generate scene cards">
      <div className="text-blue-800 text-sm max-w-full mt-2 gap-2">
        Generate scene cards from a script then view the results on the scene cards page.
        <br />
        <br />
        This feature is in open beta. We’ll continue to add more features and polish the experience
        during this time.
      </div>
    </NewFeatureAlert>
  );
}
