import { Tabs } from 'tailwindcss-stimulus-components';

export default class extends Tabs {
  connect() {
    super.connect();
  }

  change(event) {
    super.change(event);

    this.tabTargets.forEach((tab) => {
      tab.classList.remove('border-b-2', 'border-blue-500');
      tab.querySelector('a').classList.remove('text-blue-500', 'hover:text-blue-700');
      tab.querySelector('a').classList.add('text-gray-500', 'hover:text-gray-700');
    });

    event.currentTarget.classList.add('border-b-2', 'border-blue-500');
    event.currentTarget.querySelector('a').classList.remove('text-gray-500', 'hover:text-gray-700');
    event.currentTarget.querySelector('a').classList.add('text-blue-500', 'hover:text-blue-700');
  }
}
