import React from 'react';

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="p-10 sm:p-6 border border-zinc-200 bg-white xl:block hidden">
      <div className="flex flex-col items-center space-y-8 sm:flex-row sm:space-y-0 sm:space-x-6 sm:items-center sm:justify-between">
        <div className="flex space-x-6 text-sm text-gray-500 leading-5 font-medium">
          <a
            href="https://app.termly.io/document/terms-of-service/05984678-8f92-45f7-ba8e-ad68bc99f94b"
            target="_blank"
          >
            Terms of Service
          </a>
          <a
            href="https://app.termly.io/document/privacy-policy/2a58c3f5-4008-4ef9-b878-9c494ecd5598"
            target="_blank"
          >
            Privacy Policy
          </a>
        </div>
        <div className="text-center text-sm text-gray-500 leading-5 font-medium">
          &copy; {currentYear} Filmik. All rights reserved.
        </div>
      </div>
    </footer>
  );
}

export default Footer;
