import { Controller } from 'stimulus';
import Tribute from 'tributejs';
import Trix from 'trix';
import debounce from '../lib/debounce';

export default class extends Controller {
  connect() {
    this.editor = this.element.editor;
    this.initializeTribute();
  }

  disconnect() {
    this.tribute.detach(this.element);
  }

  initializeTribute() {
    this.tribute = new Tribute({
      allowSpaces: true,
      lookup: 'name',
      values: debounce(this.fetchUsers.bind(this), 200),
    });
    this.tribute.attach(this.element);
    this.tribute.range.pasteHtml = this._pasteHtml.bind(this);
    this.element.addEventListener('tribute-replaced', this.replaced);
  }

  fetchUsers(text, callback) {
    fetch(`${this.element.dataset.mentionUrl}.json?query=${text}`)
      .then((response) => response.json())
      .then((users) => callback(users))
      .catch((error) => callback([]));
  }

  _pasteHtml(html, startPos, endPos) {
    let position = this.editor.getPosition();
    this.editor.setSelectedRange([position - endPos + startPos, position + 1]);
    this.editor.deleteInDirection('backward');
  }

  replaced(event) {
    let mention = event.detail.item.original;
    let attachment = new Trix.Attachment({
      sgid: mention.sgid,
      content: mention.content,
    });
    this.editor.insertAttachment(attachment);
    this.editor.insertString(' ');
  }
}
