import React from 'react';

export default function ReviewFeatureCardItem(props) {
  return (
    <div
      className={`${props.className} flex flex-col border ${
        props.borderColor || 'border-gray-200'
      } rounded-lg p-4 w-full`}
    >
      <div className="flex mb-2">
        {props?.icon && <div className="mr-2">{props.icon}</div>}
        <div className="text-sm leading-5 font-medium text-gray-700">{props.title}</div>
      </div>
      {props?.description && (
        <span
          className={`${props.descriptionClassName} mb-2 text-sm leading-5 font-normal text-gray-500 overflow-y-auto max-h-96`}
        >
          {props.description}
        </span>
      )}
      {props.children}
    </div>
  );
}
