import React, { useState } from 'react';

import api from '../../../lib/api';
import AddButton from '../../shared/AddButton';
import Dialog from '../../shared/Dialog';
import { DropdownMenuActionType } from '../../shared/DropdownMenu';
import { showToast } from '../../shared/Toast';
import { groupByCategoryGroup } from '../calendarCategoryUtils';
import { CalendarCategorySettingsForm } from './CalendarCategoriesSettingsForm';
import CalendarCategoriesSettingsItem from './CalendarCategorySettingsItem';

interface CalendarCategoryProps {
  id: number;
  name: string;
  color: string;
  calendar_category_group: string;
}

export default function CalendarCategoriesSettings({ calendarId, items }) {
  const calendarGroups = groupByCategoryGroup(items);
  const groupNames = Object.keys(calendarGroups).sort((a, b) => b.localeCompare(a));
  const categories: CalendarCategoryProps[] = Object.values(items);

  const [editItemId, setEditItemId] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [calendarCategories, setCalendarCategories] = useState(categories);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);

  const handleSettingsUpdate = async (data) => {
    try {
      const response = await api.put(`/calendars/${calendarId}/settings`, {
        calendar_category: data,
      });

      if (response.status !== 200) {
        throw new Error('Error updating sub calendar');
      }

      setCalendarCategories((prevCategories) => {
        return prevCategories.map((category) =>
          category.id === data.id ? { ...category, ...data } : category
        );
      });

      handleCancel();
    } catch (error) {
      showToast('Error updating sub calendar', 'danger');
    }
  };

  const handleDeleteSettings = async ({ calendarCategoryId }) => {
    try {
      const response = await api.delete(
        `/calendars/${calendarId}/settings?calendar_category_id=${calendarCategoryId}`
      );

      if (response.status !== 200) {
        throw new Error('Error deleting sub calendar');
      }

      setCalendarCategories((prevCategories) =>
        prevCategories.filter((category) => category.id !== calendarCategoryId)
      );

      handleCancel();
    } catch (error) {
      showToast('Error deleting sub calendar', 'danger');
    }
  };

  const handleEdit = (subcalendarId) => {
    setEditItemId(subcalendarId);
    setIsEditModalOpen(true);
  };
  const handleCancel = () => {
    setEditItemId(null);
    setIsEditModalOpen(false);
  };

  const handleAddSubCalendar = () => {
    setIsAddDialogOpen(true);
  };

  const handleAddSubCalendarSubmit = async (data) => {
    try {
      const response = await api.post(`/calendars/${calendarId}/settings`, {
        calendar_category: data,
      });

      if (response.status !== 200) {
        throw new Error('Error creating sub calendar');
      }

      setCalendarCategories((prevCategories) => [
        ...prevCategories,
        response.data.calendar_category,
      ]);

      setIsAddDialogOpen(false);
    } catch (error) {
      showToast('Error creating sub calendar', 'danger');
    }
  };

  return (
    <div>
      <div className="flex flex-1 w-full mx-auto px-4 sm:px-6 lg:px-8 pt-10 pb-10">
        <div className="w-full">
          <div className="flex justify-between items-center">
            <span className="text-3xl leading-9 font-semibold text-gray-900">Calendar Settings</span>
            <AddButton title="Add a Sub Calendar" onClick={handleAddSubCalendar} />
          </div>
          <hr className="mt-5 mb-5" />
          <div>
            {groupNames.map((groupName) => {
              const subcalendars = calendarCategories.filter(
                (subcalendar) => subcalendar.calendar_category_group === groupName
              );
              return (
                <div key={groupName} className="mb-4">
                  {subcalendars.length > 0 && (
                    <div className="mb-2 p-2">
                      <label className="text-sm font-medium leading-5 text-gray-700">
                        {groupName}
                      </label>
                    </div>
                  )}
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                    {subcalendars.map((subcalendar) => (
                      <div key={subcalendar.id}>
                        <div>
                          <CalendarCategoriesSettingsItem
                            name={subcalendar.name}
                            color={subcalendar.color}
                            actions={[
                              {
                                label: 'Edit',
                                onClick: () => {
                                  handleEdit(subcalendar.id);
                                },
                              },
                              {
                                label: 'Remove',
                                actionType: DropdownMenuActionType.Destructive,
                                onClick: () => {
                                  handleDeleteSettings({ calendarCategoryId: subcalendar.id });
                                },
                              },
                            ]}
                          />
                        </div>
                        {editItemId === subcalendar.id && (
                          <Dialog open={isEditModalOpen} onClose={() => setIsEditModalOpen(false)}>
                            <CalendarCategorySettingsForm
                              isProductionCalendar={groupName === 'Production Calendars'}
                              initialValues={{
                                id: subcalendar.id,
                                name: subcalendar.name,
                                color: subcalendar.color,
                              }}
                              onSubmit={handleSettingsUpdate}
                              onCancel={handleCancel}
                            />
                          </Dialog>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
          <Dialog open={isAddDialogOpen} onClose={() => setIsAddDialogOpen(false)}>
            <CalendarCategorySettingsForm
              initialValues={{
                name: '',
                color: '#3B82F6',
              }}
              onSubmit={handleAddSubCalendarSubmit}
              onCancel={() => setIsAddDialogOpen(false)}
              isProductionCalendar={false}
            />
          </Dialog>
        </div>
      </div>
    </div>
  );
}
